//This function to handle the calculating of the overall score by month and department
//Note: use UTC time like the server, not local time from the client, to avoid time zone issues (e.g. 01-Jan AM on server may still be 31-Dec PM on client!)

const deploymentsOverAllScoreByMonthAndDepartment = ({
	departmentId,
	koboDeploymentArray,
	month,
	projectCode,
	projectTable,
}) => {
	let deploymentsForThisMonth
	if (projectTable) {
		deploymentsForThisMonth = koboDeploymentArray.filter(
			({ createdAt, status, check, project, score }) =>
				new Date(createdAt).getUTCMonth() === month &&
				status !== 'canceled' &&
				check.category.department.id === departmentId &&
				project.code === projectCode &&
				typeof score === 'number' &&
				score > -1
		)
	}

	if (departmentId && !projectTable) {
		deploymentsForThisMonth = koboDeploymentArray.filter(
			({ createdAt, status, check, score }) => {
				return (
					new Date(createdAt).getUTCMonth() === month &&
					status !== 'canceled' &&
					check.category.department.id === departmentId &&
					typeof score === 'number' &&
					score > -1
				)
			}
		)
	} else {
		deploymentsForThisMonth = koboDeploymentArray.filter(
			({ createdAt, status, score }) => {
				return (
					new Date(createdAt).getUTCMonth() === month &&
					status !== 'canceled' &&
					typeof score === 'number' &&
					score > -1
				)
			}
		)
	}

	if (deploymentsForThisMonth.length) {
		const scoreArr = deploymentsForThisMonth.map(({ score }) => score)
		if (scoreArr.length) {
			return parseInt(
				scoreArr.reduce((a, b) => a + b) / deploymentsForThisMonth.length
			)
		}
	} else {
		return null
	}
}
export default deploymentsOverAllScoreByMonthAndDepartment
