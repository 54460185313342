import React from 'react'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import Progress from '../UI/Progress'
const CURRENT_USER = loader('./graphql/currentUser.graphql')
/**
 * useCurrentUser Hook
 *
 * This custom hook allows you to access the current user data from the 'CurrentUserContext'.
 *
 * @returns {object} The current user data or 'null' if it's still loading.
 */
export const CurrentUserContext = React.createContext()

/**
 * Current User Provider
 *
 * This component provides a context for the current user in your application.
 * It fetches the current user data using a GraphQL query and makes it available
 * to child components via the 'CurrentUserContext'.
 *
 * @param {object} children - The child components to be wrapped with the current user context.
 *
 * @returns {JSX} A JSX component that wraps its children with the current user context.
 */
export const CurrentUserProvider = ({ children }) => {
	const { data: { currentUser = null } = {}, loading } = useQuery(CURRENT_USER)
	if (loading) return <Progress />
	return (
		<CurrentUserContext.Provider value={currentUser}>
			{children}
		</CurrentUserContext.Provider>
	)
}

export const useCurrentUser = () => React.useContext(CurrentUserContext)
