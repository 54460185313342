import {
	Button,
	FormControl,
	Table,
	TableCell,
	TableContainer,
} from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)(() => ({
	color: '#35463D',
	borderColor: 'red',
	minWidth: 200,
	'& label': {
		paddingLeft: 5,
		paddingRight: 5,
	},
	'& label.Mui-focused': {
		color: '#35463D',
	},
}))

export const StyledButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
}))

export const StyledTableContainer = styled(TableContainer)(() => ({
	width: 'fit-content',
	display: '-webkit-inline-box',
	marginTop: '20px',
}))

export const StyledTable = styled(Table)(() => ({
	textAlignLast: 'center',
}))

export const StyledTableCell = styled(TableCell)(() => ({
	fontWeight: 'bold',
}))
