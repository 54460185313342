/**
 * ManageOutOfOffice Component
 *
 * The `ManageOutOfOffice` component is a React component used for managing out-of-office data.
 * It checks if there is a current user logged in and, if not, navigates to the home page.
 * It renders a Header component and a FilterByCellOrMission component specifically for out-of-office management.
 *
 * @return {JSX.Element} A component for managing out-of-office data with a header and filter options.
 */
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import Header from '../../UI/Header'
import FilterByCellOrMission from '../../UI/FilterByCellOrMission'

const ManageOutOfOffice = () => {
	const currentUser = useCurrentUser()
	// If there is no current user, redirect to the home page.
	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			{/* Render the FilterByCellOrMission component for out-of-office management. */}
			<FilterByCellOrMission outOfOffice />
		</>
	)
}

export default ManageOutOfOffice
