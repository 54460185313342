// Sort arrays alphabetically
const sortAlphabetically = (arr) => {
	if (arr && arr.length) {
		return arr.slice().sort((a, b) => (a?.code > b?.code ? 1 : -1))
	} else {
		return []
	}
}

export default sortAlphabetically
