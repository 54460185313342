import { styled } from '@mui/material/styles'
import { Button, Table, TableCell, TableContainer } from '@mui/material'

export const StyledButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
	textTransform: 'none',
	marginLeft: '66%',
}))

export const StyledTableCell = styled(TableCell)(() => ({
	fontWeight: 'bold',
}))

export const StyledTable = styled(Table)(() => ({
	minWidth: 650,
}))

export const StyledTableContainer = styled(TableContainer)(() => ({
	width: '90%',
	margin: 'auto',
}))
