import { styled } from '@mui/material/styles'
import {
	Button,
	Container,
	TableContainer,
	Table,
	TableCell,
} from '@mui/material'

export const StyledContainer = styled(Container)(() => ({
	padding: '50px 0 0',
	textAlign: 'left',
}))

export const StyledButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
	height: '36px',
	marginLeft: '15px',
}))

export const StyledTableContainer = styled(TableContainer)(() => ({
	width: '90%',
	margin: 'auto',
}))

export const StyledTable = styled(Table)(() => ({
	minWidth: 650,
}))

export const StyledTableCell = styled(TableCell)(() => ({
	fontWeight: 'bold',
}))
