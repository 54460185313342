export const TRANSLATIONS_EN = {
	select_file: 'select a file',
	welcome_msg: 'Welcome To The Internal Monitoring Tool 3.0',
	welcome_home: 'Welcome to WeCheck Home',
	joined_groups: 'Joined Groups',
	department: 'Department',
	project: 'Project',
	name: 'Name',
	file_upload: 'File Upload',
	upload_file_to: 'Upload file to',
	create_check: 'Create a Check',
	select_xlsx_file_and_drag: 'Select .xlsx file or drag it here',
	check_date_period: 'Send Date & Period',
	period: 'period',
	month: 'month',
	set_send_date_and_period: 'Set Send Date and Period',
	save: 'Save',
	update: 'Update',
	set_period: 'Set Send Period',
	set_date: 'Set Send Date',
	future_date_error: 'Please choose a future date',
	cell: 'Cell',
	mission: 'Mission',
	filter_by: 'Filter By',
	checks: 'Checks',
	submitted_checks: 'Submitted Checks',
	pending_checks: 'Pending Checks',
	overdue_checks: 'Overdue Checks',
	of_targets_hit: 'Of targets hit',
	the_overall_performance: 'The Overall Performance',
	keep_track: 'Keep track of the overall numbers',
	you_have_submitted: 'You have submitted',
	hello: 'Hello',
	view_answers: 'View Answers',
	you_have_answered: 'You have answered',
	questions: 'questions',
	receive_date: 'Receive Date',
	send_date: 'Send Date',
	status: 'Status',
	view: 'View',
	submit_date: 'Submit date',
	project_code: 'Project code',
	check: 'Check',
	check_form: 'Check Form',
	archived: 'Archived',
	all: 'All',
	sent: 'Sent',
	received: 'Received',
	overdue: 'Overdue',
	link: 'Link',
	click_here: 'Click here',
	to_review_your_answers: 'to review your answers',
	recently_have_submitted: 'You recently have submitted',
	one: 'One',
	pending: 'Pending',
	completed: 'completed',
	to_complete_submitting_checks: 'to complete submitting your monthly checks',
	you_have: 'You have',
	is: 'is',
	are: 'are',
	corrective_measures: 'corrective measures',
	the_score_is: 'The score is',
	priority: 'Priority',
	corrective_measures_thanks: 'Thanks for submitting the corrective measures',
	corrective_measures_form: 'Corrective Measures Form',
	form_error_msg: 'Please make sure all fields are filled in',
	submitted_corrective_measures: 'Submitted Corrective Measures',
	score: 'Score',
	submit_corrective_measures: 'Submit Corrective Measures',
	corrective_measures_required: 'Corrective measures required',
	the_function_in_charge: 'The function in charge',
	due_date: 'Due date',
	low: 'low',
	received_checks: 'Received Checks',
	medium: 'medium',
	high: 'high',
	all_missions: 'All Missions',
	all_cells: 'All Cells',
	all_projects: 'All Projects',
	all_departments: 'All Departments',
	from: 'from date',
	to: 'to date',
	corrective_measures_score: 'Corrective measures score',
	graph: 'graph',
	year: 'year',
	num_of_received_checks: '# of Received Checks',
	num_of_pending_checks: '# of Pending Checks',
	num_of_sent_checks: '# of Sent Checks',
	num_of_overdue_checks: '# of Overdue Checks',
	january: 'January',
	february: 'February',
	march: 'March',
	april: 'April',
	may: 'May',
	june: 'June',
	july: 'July',
	august: 'August',
	september: 'September',
	october: 'October',
	november: 'November',
	december: 'December',
	sent_checks: 'Sent Checks',
	submit: 'Submit',
	home: 'Home',
	manage_projects: 'Project Management',
	upload: 'Upload',
	sign_out: 'Sign out',
	sign_in: 'Sign in',
	group_name: 'Group name',
	create_group: 'Create a group',
	assign_projects_to_groups: 'Assign Projects to Groups',
	add_groups_to_checks: 'Add groups to checks',
	back_home: 'Back Home',
	email: 'email',
	out_of_office: 'Out of the office',
	in_office_date: 'In office date',
	manage_out_of_office: 'Manage Out of office',
	manage_users: 'Manage users',
	canceled: 'Canceled',
	impact: 'Impact',
	person_in_charge: 'Person in charge',
	in_charge: 'in charge',
	corrective_measures_link: 'Corrective measures link',
	sample: 'Sample',
	monthly_pending_checks: 'Monthly pending checks',
	project_summary: 'Project Summary',
	mission_summary: 'Mission Summary',
	summary_comment_text: 'Please add a comment here to summarise',
	summary_comment: 'Summary comment',
	about_1:
		'The Internal Monitoring Tool (WeCheck) is an auto-assessment exercise based on a set of predefined checks to be performed regularly on all projects and in all missions by all department supervisors (Supply, Logistics, Finance, Human Resources, Medical and Operations).',
	about_2:
		' Through this exercise, the department supervisors monitor if OCB validated basic procedures or processes are properly and systematically applied in their departments. It is therefore an important tool for the management of the project by the departments supervisors and the Project Coordinator.”',
	label: 'label',
	category: 'category',
	last_send_date: 'last send date',
	timing: 'Timing',
	project_comment: 'project comment',
	mission_comment: 'mission comment',
	period_checks: 'period checks',
	monthly_completed_checks: 'Monthly completed checks',
	support: 'Support',
	average_Q1: 'Average Q1',
	average_Q2: 'Average Q2',
	average_Q3: 'Average Q3',
	average_Q4: 'Average Q4',
	clear_filter: 'Clear filter',
	department_not_present: 'department not present',
	results_by_department_month: 'Results by department by month',
	results_by_project_month: 'Results by project by month',
	avg_quarter_by_department: 'Average quarterly results by department',
	avg_quarter_by_project: 'Average quarterly results by project',
	evolution_avg_quarterly_by_department:
		'Evolution of average quarterly results by department',
	evolution_avg_quarterly_by_project:
		'Evolution of average quarterly results by project',
	list_of_cm: 'List of corrective measures',
	checks_are_out: 'checks are out now',
	here: 'here',
	checks_home_page_msg:
		'Do not forget to complete your monthly monitoring before the end of the month by clicking',
	checks_are_still_pending: 'controls are still pending',
	great_job: 'Great job',
	checks_have_been_submitted: 'checks have been submitted',
	present: 'present',
	not_present: 'not present',
	departments_checks_results_by_month: "Department's checks results by month",
	submitted: 'Submitted',
	comment: 'comment',
	checks_of_month: 'the checks of',
	have_been_completed: 'have been completed',
	error: 'Error',
	access_error: 'You are trying to access WeCheck using this email',
	screenshot_msg: 'Please take a screenshot and send it to',
	change_request:
		'If you would like to request a change / removal / addition of one of the checks, please contact',
	following_info: 'with the following information',
	your_mission: 'Your mission',
	your_project: 'Your project',
	your_department: 'Your department',
	change_question: 'Question you would like to change',
	contextual_reason: 'Contextual reason',
	techincal_contact: 'If you have technical issues, please contact',
	view_questions: 'View Questions',
	modify: 'Modify',
	success_request_msg: 'Your request has been submitted successfully!',
	non_inOfficeDate_msg:
		'Note: If you wish not to set a back to office date, please click on clear after you set "from date" as the screenshot below shows then click on save.',
	mark_ooo_msg: `This project will show that your entire department is currently not available, so no checks will be sent to your department until it's available again.`,
	send: 'Send',
	no_user_found: 'There is no user assigned to this project or this department',
	send_now: 'Send Now',
	user: 'User',
	send_check_error:
		'There was an error during sending the check, please try again later or contact the admin',
	send_check_success_msg: 'The check has been sent successfully',
	wait: 'Please Wait!',
	powerbi_report: 'PowerBI Report',
	no_user_assigned_department_project: 'No user assigned to this department in this project',
}
