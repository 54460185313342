import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import Progress from '../../UI/Progress'
import FilterSelect from '../../UI/FilterSelect'
import Header from '../../UI/Header'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { StyledButton } from '../UpdateCheck/useStyles'
import { sortAlphabetically } from '../../functions'
import '../UpdateCheck/styles.css'
const CELLS = loader('./graphql/cells.graphql')
const CHECK = loader('./graphql/check.graphql')
const DEPLOY_CHECK_TO_SINGLE_PROJECT = loader(
	'./graphql/deployCheckToSingleProject.graphql'
)
const MISSIONS = loader('./graphql/missions.graphql')
const USER_BY_PROJECT_AND_DEPARTMENT = loader(
	'./graphql/userByProjectAndDepartment.graphql'
)

const SendCheck = () => {
	const { name } = useParams()
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	// Check if the user is admin
	const isSuperAdmin = currentUser?.groups.find(
		({ group }) => group === 'superAdmin'
	)
	// Check if the user is FRRIC
	const isFRRIC = currentUser?.groups.find(
		({ group }) => group === 'SEC_OCB_USR_FIN_FRRIC_RegionalOfficers'
	)
	const [filterBy, setFilterBy] = useState('mission')
	const [selectedCode, setSelectedCode] = useState()
	const [selectedProjectCode, setSelectedProjectCode] = useState()

	const { data: { cells = null } = {}, loading: cellsLoading } = useQuery(CELLS)
	const { data: { missions = null } = {}, loading: missionsLoading } =
		useQuery(MISSIONS)

	const { data: { check = null } = {} } = useQuery(CHECK, {
		variables: {
			name,
		},
	})
	const [
		userByProjectAndDepartmentData,
		{ data: { userByProjectAndDepartment = null } = {} },
	] = useLazyQuery(USER_BY_PROJECT_AND_DEPARTMENT)

	const [
		deployCheckToSingleProject,
		{ data: deployCheckToSingleProjectData = {}, loading, error },
	] = useMutation(DEPLOY_CHECK_TO_SINGLE_PROJECT)

	const handleDeployCheck = () => {
		deployCheckToSingleProject({
			variables: { input: { projectCode: selectedProjectCode, name } },
		})
	}

	useEffect(() => {
		if (check && selectedProjectCode) {
			userByProjectAndDepartmentData({
				variables: {
					projectCode: selectedProjectCode,
					departmentId: check.category.department.id,
				},
			})
		}
	}, [check, selectedProjectCode])

	// Ensure the user is authenticated && user role is admin or FRRIC
	if (!currentUser || (!isSuperAdmin && !isFRRIC)) return <Navigate to="/" />

	return (
		<>
			<Header />
			{check && <h1>{language === 'fr' ? check.labelFr : check.label}</h1>}
			{cellsLoading || missionsLoading ? (
				<Progress />
			) : (
				cells &&
				missions && (
					<>
						{/*Filter by Cell or Mission*/}
						<FilterSelect
							label={t('filter_by')}
							value={filterBy}
							options={['cell', 'mission'].map((el) => ({
								label: t(el),
								value: el,
							}))}
							onChange={({ target: { value } }) => {
								setFilterBy(value)
								setSelectedCode(null)
							}}
						/>
						<FilterSelect
							menuItemValue="code"
							label={filterBy === 'mission' ? t('mission') : t('cell')}
							value={selectedCode || ''}
							options={
								filterBy === 'cell'
									? sortAlphabetically(cells)
									: sortAlphabetically(missions)
							}
							onChange={({ target: { value } }) => {
								setSelectedCode(value)
							}}
						/>
						{/* Filter by Project Code */}
						<FilterSelect
							menuItemValue="code"
							label={t('project')}
							value={selectedProjectCode || ''}
							options={
								selectedCode && filterBy === 'cell'
									? sortAlphabetically(
											cells.find(({ code }) => code === selectedCode)?.projects
									  )
									: selectedCode && filterBy === 'mission'
									? sortAlphabetically(
											missions.find(({ code }) => code === selectedCode)
												?.projects
									  )
									: []
							}
							onChange={({ target: { value } }) => {
								setSelectedProjectCode(value)
							}}
						/>
						{/*User assigned to this project and the check's department*/}
						{userByProjectAndDepartment && (
							<>
								{loading ? (
									<div>
										<p>{t('wait')}</p>
										<Progress />
									</div>
								) : error ? (
									<p>{t('send_check_error')}</p>
								) : deployCheckToSingleProjectData?.deployCheckToSingleProject
										?.deployed ? (
									<p>{t('send_check_success_msg')}</p>
								) : (
									<>
										{' '}
										<p>{`${t('user')}: ${
											userByProjectAndDepartment?.email
										}`}</p>
										<StyledButton
											type="submit"
											variant="contained"
											color="secondary"
											onClick={handleDeployCheck}
										>
											{t('send_now')}
										</StyledButton>
									</>
								)}
							</>
						)}
						{/*If the projectCode is selected & there is no user found in this project and this department*/}
						{selectedProjectCode && !userByProjectAndDepartment && (
							<p>{t('no_user_found')}</p>
						)}
					</>
				)
			)}
		</>
	)
}

export default SendCheck
