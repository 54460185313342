/**
 * MissionSummary Component
 *
 * The MissionSummary component provides a summary of a specific mission for the current month and allows users to provide summary comments. It displays key details and comments for the selected mission.
 *
 * @returns {JSX} A component that displays mission details and allows users to provide summary comments for the current month.
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Navigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { Button, TextField } from '@mui/material'

import CheckTable from '../../UI/CheckTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import useStyles from './useStyles'

const MissionSummary = () => {
	const { code } = useParams()
	const [comment, setComment] = useState('')
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const months = [
		t('january'),
		t('february'),
		t('march'),
		t('april'),
		t('may'),
		t('june'),
		t('july'),
		t('august'),
		t('september'),
		t('october'),
		t('november'),
		t('december'),
	]
	const { btn, inputFieldMissionSummary, missionSummaryFormContainer } =
		useStyles()
	const KOBO_DEPLOYMENT = loader(
		'./graphql/receivedKoboDeploymentByMissionDepartmentAndMonth.graphql'
	)
	const CREATE_COMMENT = loader(
		'./graphql/createMissionCommentByDepartment.graphql'
	)
	const FETCH_COMMENT = loader(
		'./graphql/departmentAndMissionCommentByMonth.graphql'
	)

	const [
		koboDeploymentData,
		{
			data: { receivedKoboDeploymentByMissionDepartmentAndMonth = null } = {},
			loading,
		},
	] = useLazyQuery(KOBO_DEPLOYMENT)

	const [createMissionCommentByDepartment, { data, loading: createLoading }] =
		useMutation(CREATE_COMMENT)

	const [
		commentByProjectData,
		{
			data: { departmentAndMissionCommentByMonth = null } = {},
			loading: fetchLoading,
			refetch,
		},
	] = useLazyQuery(FETCH_COMMENT)

	const handleSubmitComment = () => {
		return createMissionCommentByDepartment({
			variables: {
				input: {
					comment,
					missionCode: code,
					departmentId: currentUser.departments[0].id,
				},
			},
		})
	}

	useEffect(() => {
		if (data) {
			refetch()
		}
	}, [data])

	useEffect(() => {
		if (currentUser) {
			koboDeploymentData({
				variables: {
					mission_code: code,
					departmentId: currentUser.departments[0].id,
					month: Number(new Date().getMonth() + 1),
					year: Number(new Date().getFullYear()),
				},
			})

			commentByProjectData({
				variables: {
					missionCode: code,
					departmentId: currentUser.departments[0].id,
					month: Number(new Date().getMonth() + 1),
				},
			})
		}
	}, [code, currentUser])

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<Header />
			<h2>{`${t('mission_summary')} - ${
				months[new Date().getMonth()]
			} ${new Date().getFullYear()}`}</h2>
			{loading ? (
				<Progress />
			) : (
				receivedKoboDeploymentByMissionDepartmentAndMonth && (
					<>
						<CheckTable
							koboDeploymentArray={
								receivedKoboDeploymentByMissionDepartmentAndMonth
							}
						/>
						<div className={missionSummaryFormContainer}>
							<h3>{t('summary_comment')}</h3>

							{departmentAndMissionCommentByMonth ? (
								<p>{departmentAndMissionCommentByMonth.comment}</p>
							) : (
								<>
									<TextField
										className={inputFieldMissionSummary}
										id="outlined-basic"
										label="summary comment"
										variant="outlined"
										required
										name="summary comment"
										color="secondary"
										multiline
										value={comment}
										onChange={({ target: { value } }) => setComment(value)}
									/>
									<Button
										type="submit"
										variant="contained"
										color="secondary"
										className={btn}
										onClick={handleSubmitComment}
									>
										{t('Submit')}
									</Button>
								</>
							)}
						</div>
					</>
				)
			)}
		</>
	)
}

export default MissionSummary
