import { styled } from '@mui/material/styles'

const useStyles = styled(() => ({
	cellContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginTop: '20px',
	},
	container: {
		border: '1px solid #FEFEFE',
		backgroundColor: '#FEFEFE',
		width: 'fit-content',
		padding: '27px',
		minWidth: '50%',
		borderRadius: '11px',
	},
	// groupsContainer: {
	// 	display: 'flex',
	// 	justifyContent: 'center',
	// 	flexDirection: 'column',
	// 	alignItems: 'center',
	// },
	groupList: {
		textAlign: 'left',
		listStyle: 'circle',
		marginTop: 0,
	},
	mainContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		marginTop: '20px',
	},
}))

export default useStyles
