/**
 * DateFilter Component
 *
 * The `DateFilter` component is a reusable input component used for selecting dates.
 *
 * @param {string} label - The label for the date input field.
 * @param {string} value - The selected date value.
 * @param {function} onChange - A callback function to handle changes to the selected date.
 *
 * @return {JSX.Element} A component for selecting and filtering dates using a date picker.
 */
import React from 'react'
import { TextField } from '@mui/material'

import { StyledFormControl } from '../FilterByCellOrMission/useStyles'
const DateFilter = ({ label, value, onChange }) => {
	return (
		<StyledFormControl variant="outlined" required color="secondary">
			<TextField
				id="outlined-basic"
				label={label}
				variant="outlined"
				name="date"
				type="date"
				color="secondary"
				format="YYYY-MM-DD"
				InputLabelProps={{ shrink: true }}
				onChange={onChange}
				value={value}
			/>
		</StyledFormControl>
	)
}

export default DateFilter
