import React from 'react'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import { StyledPaperPending } from './useStyles'

const PendingChecksTable = ({ rows }) => {
	const { t } = useTranslation()
	const language = window.localStorage.i18nextLng
	const sortAlphabetically = (arr) => {
		return arr.slice().sort((a, b) => (a.check.label > b.check.label ? 1 : -1))
	}

	const columns = [
		{
			field: 'name',
			headerName: t('name'),
			valueGetter: ({ row }) =>
				language === 'fr' ? row.check.labelFr : row.check.label,
			flex: 1.5,
		},
		{
			field: 'createdAt',
			headerName: t('receive_date'),
			renderCell: ({ row }) => {
				return (
					<Moment format="DD-MM-YYYY">{new Date(Number(row.createdAt))}</Moment>
				)
			},
			flex: 1,
		},

		{
			field: 'link',
			headerName: t('link'),
			renderCell: ({ row }) => {
				return (
					<a target="_blank" rel="noreferrer" href={row.link}>
						{t('check_form')}
					</a>
				)
			},

			flex: 1,
		},
	]
	return (
		<StyledPaperPending>
			{rows && (
				<DataGridPro
					autoHeight
					columns={columns}
					rows={sortAlphabetically(rows)}
					components={{ Toolbar: GridToolbar }}
				/>
			)}
		</StyledPaperPending>
	)
}
export default PendingChecksTable
