import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Navigate } from 'react-router-dom'
import Header from '../../UI/Header'
import './styles.css'

const Support = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<div className="supportContainer">
				<h2>{t('support')}</h2>
				<p>
					{t('change_request')}{' '}
					<a href={'mailto: wecheck@brussels.msf.org'}> WeCheck </a>
					{t('following_info')}:
				</p>
				<ul className="list">
					<li>{t('your_mission')}</li>
					<li>{t('your_project')}</li>
					<li>{t('your_department')}</li>
					<li>{t('change_question')}</li>
					<li>{t('contextual_reason')}</li>
				</ul>
				<p>
					{t('techincal_contact')}{' '}
					<a href={'mailto: Fin-Tools@brussels.msf.org'}> FIN Tools </a>
				</p>
			</div>
		</>
	)
}

export default Support
