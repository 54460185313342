import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { TextField } from '@mui/material'
import { StyledButton } from './useStyles'
import './styles.css'

const CREATE_BATCH = loader('./graphql/createBatch.graphql')
const BATCHES = loader('../CheckToBatches/graphql/batches.graphql')

const AddBatchBox = () => {
	const { t } = useTranslation()
	const [batchName, setBatchName] = useState('')
	const [createBatch, { loading }] = useMutation(CREATE_BATCH)

	const handleCreateBatch = () => {
		if (batchName.trim().length === 0) {
			return alert(t('form_error_msg'))
		}
		createBatch({
			optimisticResponse: {
				createBatch: {
					__typename: 'CreateBatchPayload',
					batch: {
						id: '00000000',
						name: batchName.trim(),
						__typename: 'Batch',
					},
				},
			},
			update: (cache, { data }) => {
				const {
					createBatch: { batch: createdBatch },
				} = data
				const { batches } = cache.readQuery({
					query: BATCHES,
				})
				cache.writeQuery({
					query: BATCHES,
					data: {
						batches: [...batches, createdBatch],
					},
				})
			},
			variables: {
				input: {
					name: batchName.trim(),
				},
			},
		})

		return setBatchName('')
	}

	return (
		<>
			<div className="batchBoxContainer">
				<span style={{ marginRight: '10px' }}>{t('create_group')}:</span>
				<TextField
					required
					id="outlined-basic"
					label={t('group_name')}
					variant="outlined"
					value={batchName}
					onChange={({ target: { value } }) => {
						setBatchName(value)
					}}
					name="groupName"
					type="text"
					color="secondary"
				/>
				<StyledButton
					type="submit"
					variant="contained"
					color="secondary"
					onClick={handleCreateBatch}
				>
					{t('create')}
				</StyledButton>
			</div>
		</>
	)
}

export default AddBatchBox
