import { styled } from '@mui/material/styles'

const useStyles = styled({
	inputFieldMissionSummary: {
		width: '50%',
	},
	btn: {
		backgroundColor: '#FD0000',
		marginTop: '10px',
	},
	missionSummaryFormContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '20px',
	},
})

export default useStyles
