/**
 * Landing Component
 *
 * The Landing component is the initial landing page of the application. It is displayed to non-logged-in users and provides a welcome message, a world map image, and information about the application.
 *
 * @returns {JSX} A component that serves as the initial landing page for non-logged-in users, providing a welcome message, a world map image, and information about the application.
 */
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import Header from '../../UI/Header'
import { StyledContainer } from './useStyles'
import worldMap from '../../../images/world-map.png'

const Landing = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	if (currentUser) return <Navigate to={'/home'} />
	return (
		<>
			<Header />
			<h1>{t('welcome_msg')}</h1>
			<div style={{ padding: '60px 0 0' }}>
				<img src={worldMap} alt="world-map" style={{ width: '50%' }} />
			</div>
			<StyledContainer maxWidth="md">
				<h3>About</h3>
				<p>
					{t('about_1')}
					{t('about_2')}
				</p>
			</StyledContainer>
		</>
	)
}

export default Landing
