import { styled } from '@mui/material/styles'
import { FormControl } from '@mui/material'

export const StyledFormControl = styled(FormControl)(() => ({
	marginBottom: '50px',
	color: '#35463D',
	borderColor: 'red',
	minWidth: 210,
	marginLeft: '20px',
	marginRight: '20px',
	'& label': {
		backgroundColor: '#F9F9F9',
		paddingLeft: 5,
		paddingRight: 5,
	},
	'& label.Mui-focused': {
		color: '#35463D',
	},
}))
