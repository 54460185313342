import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { StyledPaper } from './useStyles'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import Progress from '../Progress'
// import useStyles from './useStyles'

import { handleCalculatingOverAllScoreForKoboDeploymentArrPerMonth } from './functions'

const CELL = loader('./graphql/cell.graphql')

const MISSION = loader('./graphql/mission.graphql')

const PROJECT_COMMENT = loader(
	'./graphql/departmentAndProjectCommentByMonth.graphql'
)

const ALL_COMMENTS_PER_MISSION = loader(
	'./graphql/allCommentsByMission.graphql'
)

const ALL_PROJECTS = loader('./graphql/projects.graphql')

const TableOne = ({
	departmentId,
	missionCode,
	cellCode,
	koboDeploymentArray,
	projectCode,
	months,
	year,
}) => {
	let totalArr = []
	const { t } = useTranslation()
	const [projectArr, setProjectArr] = useState()
	// const { paper, commentCell, clicked, customTableContainer } = useStyles()
	const [selectedMonth, setSelectedMonth] = useState()
	//const columns = [t('project'), ...months, t('project_comment')]

	const [resultsDataGridRows, setResultsDataGridRows] = useState([])
	const resultsDataGridRowsMax = 10
	const resultsDataGridRowHeight = 51

	const monthsColumns = months.map((month, i) => {
		return {
			field: month,
			headerName: t(month),
			renderCell: ({ row }) => {
				if (row.id === 'total') {
					const findIndex = totalArr.find((obj) => obj.i === i)
					if (findIndex) {
						const avg = Math.round(findIndex.avgTotal / findIndex.devidedBy)
						return `${avg}%`
					}
				} else {
					const avgTotal =
						handleCalculatingOverAllScoreForKoboDeploymentArrPerMonth({
							koboDeploymentArray,
							departmentId:
								departmentId === 'all departments'
									? null
									: Number(departmentId),
							projectCode: row.code,
							month: i,
						})
					if (typeof avgTotal === 'number' && avgTotal > -1) {
						const found = totalArr.find(({ i: index }) => index === i)
						if (found) {
							const newObj = {
								i,
								avgTotal: avgTotal + found.avgTotal,
								devidedBy: found.devidedBy + 1,
							}
							totalArr = totalArr.filter(({ i: index }) => index !== found.i)
							totalArr = [...totalArr, newObj]
						} else {
							totalArr.push({ i, avgTotal, devidedBy: 1 })
						}
					}

					return typeof avgTotal === 'number' && avgTotal > -1
						? `${avgTotal}%`
						: '%'
				}
			},
			flex: 1,
		}
	})

	const gridColumns = [
		{
			field: 'project',
			headerName: t('project'),
			valueGetter: ({ row }) => row?.code,
			flex: 1.5,
		},
		...monthsColumns,
	]

	const [
		missionData,
		{ data: { mission = null } = {}, loading: missionLoading },
	] = useLazyQuery(MISSION)

	const [cellData, { data: { cell = null } = {}, loading: cellLoading }] =
		useLazyQuery(CELL)

	const [
		commentByProjectData,
		{
			data: { departmentAndProjectCommentByMonth = null } = {},
			loading: projectCommentLoading,
		},
	] = useLazyQuery(PROJECT_COMMENT)

	const [
		getAllProjects,
		{ data: { projects = null } = {}, loading: projectsLoading },
	] = useLazyQuery(ALL_PROJECTS)

	const [
		allCommentsByMissionData,
		{
			data: { allCommentsByMission = null } = {},
			loading: allCommentsByMissionLoading,
		},
	] = useLazyQuery(ALL_COMMENTS_PER_MISSION)

	// const handleCellClick = (month) => {
	// 	return setSelectedMonth(month)
	// }

	useEffect(() => {
		if (missionCode && missionCode !== 'all missions') {
			missionData({
				variables: { code: missionCode },
			})
		} else if (cellCode && cellCode !== 'all cells') {
			cellData({ variables: { code: cellCode } })
		}
	}, [missionCode, cellCode])

	useEffect(() => {
		if (cellCode === 'all cells' || missionCode === 'all missions') {
			getAllProjects()
		}
	}, [cellCode, missionCode])

	useEffect(() => {
		if (cell) {
			setProjectArr(cell.projects)
		}
		if (mission) {
			setProjectArr(mission.projects)
		}
		if (projects) {
			setProjectArr(projects)
		}
	}, [cell, mission, projects])

	useEffect(() => {
		if (
			selectedMonth &&
			departmentId !== 'all departments' &&
			projectCode &&
			projectCode !== 'all projects'
		) {
			return commentByProjectData({
				variables: {
					month: selectedMonth,
					departmentId,
					projectCode,
					year,
				},
			})
		}

		if (
			selectedMonth &&
			departmentId !== 'all departments' &&
			projectCode &&
			projectCode === 'all projects' &&
			missionCode &&
			missionCode !== 'all missions'
		) {
			allCommentsByMissionData({
				variables: {
					month: selectedMonth,
					departmentId,
					missionCode,
					year,
				},
			})
		}
	}, [selectedMonth, departmentId, projectCode])

	useEffect(() => {
		if (projectArr?.length) {
			const lastRow = { id: 'total', code: 'total' }
			setResultsDataGridRows(
				!projectCode || projectCode === 'all projects'
					? [...projectArr, lastRow]
					: [...projectArr.filter(({ code }) => code === projectCode), lastRow]
			)
		}
	}, [projectArr, projectCode])

	if (missionLoading || cellLoading) return <Progress />

	return (
		<>
			{resultsDataGridRows && (
				<>
					<h3>{t('results_by_project_month')}</h3>
					{/* If more results rows than `resultsDataGridRowsMax`, cap the height of the data grid and make it scrollable */}
					<StyledPaper
						style={{
							height: `${
								resultsDataGridRowHeight *
								Math.min(
									resultsDataGridRowsMax,
									Math.max(5, resultsDataGridRows?.length)
								)
							}px`,
						}}
					>
						<DataGridPro
							autoHeight={resultsDataGridRows?.length <= resultsDataGridRowsMax}
							columns={gridColumns}
							rows={resultsDataGridRows}
							components={{ Toolbar: GridToolbar }}
						/>
					</StyledPaper>
				</>
			)}
		</>
	)
}

export default TableOne
