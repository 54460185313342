import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

import Progress from '../../UI/Progress'
import { StyledButton, StyledTextField } from './useStyles'
import './styles.css'

const ProjectSummary = ({ code, departmentId }) => {
	const [comment, setComment] = useState('')
	const currentUser = useCurrentUser()
	const { t } = useTranslation()

	const KOBO_DEPLOYMENT = loader(
		'./graphql/receivedKoboDeploymentByProjectDepartmentAndMonth.graphql'
	)
	const CREATE_COMMENT = loader(
		'./graphql/createProjectCommentByDepartment.graphql'
	)
	const UPDATE_COMMENT = loader(
		'./graphql/updateProjectCommentByDepartment.graphql'
	)
	const FETCH_COMMENT = loader(
		'./graphql/departmentAndProjectCommentByMonth.graphql'
	)

	const [
		koboDeploymentData,
		{
			data: { receivedKoboDeploymentByProjectDepartmentAndMonth = null } = {},
			loading,
		},
	] = useLazyQuery(KOBO_DEPLOYMENT)

	const [createProjectCommentByDepartment, { data, loading: createLoading }] =
		useMutation(CREATE_COMMENT)

	const [
		updateProjectCommentByDepartment,
		{ data: updateCommentData, loading: updateLoading },
	] = useMutation(UPDATE_COMMENT)

	const [
		commentByProjectData,
		{
			data: { departmentAndProjectCommentByMonth = null } = {},
			loading: fetchLoading,
			refetch,
		},
	] = useLazyQuery(FETCH_COMMENT)

	const handleSubmitComment = () => {
		return createProjectCommentByDepartment({
			variables: {
				input: {
					comment,
					projectCode: code,
					departmentId: departmentId,
				},
			},
		})
	}

	const handleUpdateComment = ({ id }) => {
		return updateProjectCommentByDepartment({
			variables: {
				input: {
					comment,
					id,
				},
			},
		})
	}

	useEffect(() => {
		if (data) {
			refetch()
		}
	}, [data])

	useEffect(() => {
		if (currentUser) {
			koboDeploymentData({
				variables: {
					projectCode: code,
					departmentId,
					month: Number(new Date().getMonth() + 1),
					year: Number(new Date().getFullYear()),
				},
			})

			commentByProjectData({
				variables: {
					projectCode: code,
					departmentId,
					month: Number(new Date().getMonth() + 1),
					year: Number(new Date().getFullYear()),
				},
			})
		}
	}, [code, currentUser])

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			{loading || updateLoading || createLoading ? (
				<Progress />
			) : (
				receivedKoboDeploymentByProjectDepartmentAndMonth && (
					<>
						<div className="projectSummaryFormContainer">
							{departmentAndProjectCommentByMonth ? (
								<>
									<h3>{t('summary_comment_text')}</h3>
									<StyledTextField
										id="outlined-basic"
										variant="outlined"
										required
										name="summary comment"
										color="secondary"
										multiline
										defaultValue={departmentAndProjectCommentByMonth.comment}
										onChange={({ target: { value } }) => setComment(value)}
									/>
									<StyledButton
										type="submit"
										variant="contained"
										color="secondary"
										onClick={() =>
											handleUpdateComment({
												id: departmentAndProjectCommentByMonth.id,
											})
										}
									>
										{t('update')}
									</StyledButton>
								</>
							) : (
								<>
									<h3>{t('summary_comment_text')}</h3>
									<StyledTextField
										id="outlined-basic"
										label={t('summary_comment')}
										variant="outlined"
										required
										name="summary comment"
										color="secondary"
										multiline
										value={comment}
										onChange={({ target: { value } }) => setComment(value)}
									/>
									<StyledButton
										type="submit"
										variant="contained"
										color="secondary"
										onClick={handleSubmitComment}
									>
										{t('submit')}
									</StyledButton>
								</>
							)}
						</div>
					</>
				)
			)}
		</>
	)
}

export default ProjectSummary
