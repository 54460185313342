/**
 * Department Component
 *
 * The Department component displays a list of checks organized by departments and their details. It provides the ability to filter checks by department and view additional information for each check, including name, label, category, last send date, and send date. SuperAdmin users have the option to modify checks as well.
 *
 * @returns {JSX} A component for managing and viewing checks organized by departments.
 */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate } from 'react-router-dom'
import Moment from 'react-moment'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../CurrentUserContext'

import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import { MenuItem, InputLabel, Select } from '@mui/material'
import Progress from '../../UI/Progress'
import Header from '../../UI/Header'

import { StyledFormControl, StyledEditIcon, StyledSendIcon } from './useStyles'

const ALL_DEPARTMENTS = loader('./graphql/allDepartments.graphql')

const Department = () => {
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const [departmentId, setDepartmentId] = useState(() => {
		return window.localStorage.getItem('departmentId') || ''
	})
	const isSuperAdmin = currentUser?.groups.find(
		({ group }) => group === 'superAdmin'
	)
	const isFrric = currentUser?.groups.find(
		({ group }) => group === 'SEC_OCB_USR_FIN_FRRIC_RegionalOfficers'
	)
	const {
		data: { allDepartments = null } = {},
		loading: departmentsLoading,
		refetch,
	} = useQuery(ALL_DEPARTMENTS)

	const setDepartmentIdState = (value) => {
		window.localStorage.setItem('departmentId', value)
		setDepartmentId(value)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		refetch()
	}, [])

	const columns = [
		{
			field: 'name',
			headerName: t('name'),
			valueGetter: ({ row }) => `${row?.name}`,
			flex: 2,
		},
		{
			field: 'label',
			headerName: t('label'),
			valueGetter: ({ row }) =>
				`${language === 'fr' ? row?.labelFr : row?.label}`,
			flex: 1,
		},
		{
			field: 'category',
			headerName: t('category'),
			valueGetter: ({ row }) =>
				`${language === 'fr' ? row?.category?.labelFr : row?.category?.label}`,
			flex: 1,
		},
		{
			field: 'lastSendDate',
			headerName: t('last_send_date'),
			renderCell: ({ row }) => {
				return <Moment format="DD-MM-YYYY">{row.lastSendDate}</Moment>
			},

			flex: 1,
		},
		{
			field: 'sendDate',
			headerName: t('send_date'),
			renderCell: ({ row }) => {
				return <Moment format="DD-MM-YYYY">{row.sendDate}</Moment>
			},

			flex: 1,
		},

		{
			field: 'sendPeriod',
			headerName: `${t('period')}/${t('month')}`,
			renderCell: ({ row }) => (
				<Moment format="DD-MM-YYYY">{row.sendDate}</Moment>
			),
			flex: 1,
		},
		{
			field: 'questions',
			headerName: t('questions'),
			renderCell: ({ row }) => (
				<Link to={`/check/${row.name}`}>{t('view_questions')}</Link>
			),
			flex: 1,
		},
		{
			field: t('send'),
			flex: 0.5,
			hide: !isSuperAdmin && !isFrric,
			renderCell: ({ row }) => {
				return (
					<Link
						to={`/sendCheck/${row.name}`}
						style={{
							textDecoration: 'none',
							color: '#FD0000',
						}}
					>
						<StyledSendIcon />
					</Link>
				)
			},
		},
		{
			field: t('modify'),
			flex: 0.5,
			hide: !isSuperAdmin,
			renderCell: ({ row }) => {
				return (
					<Link
						to={`/updateCheck/${row.name}`}
						style={{
							textDecoration: 'none',
							color: '#FD0000',
						}}
					>
						<StyledEditIcon />
					</Link>
				)
			},
		},
	]

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<Header />
			{departmentsLoading ? (
				<Progress />
			) : (
				allDepartments && (
					<>
						<StyledFormControl variant="outlined" required color="secondary">
							<InputLabel id="departmentLabel">{t('department')}</InputLabel>
							<Select
								labelId="InputLabel"
								name="department"
								value={departmentId}
								onChange={({ target: { value } }) => {
									setDepartmentIdState(value)
								}}
							>
								{allDepartments.length &&
									allDepartments.map(({ name, id }) => (
										<MenuItem key={id} value={id}>
											{name}
										</MenuItem>
									))}
							</Select>
						</StyledFormControl>
						{departmentId && (
							<DataGridPro
								autoHeight
								columns={columns}
								rows={allDepartments
									.find(({ id }) => id === departmentId)
									.categories.map(({ checks }) => checks)
									.flat()}
								components={{ Toolbar: GridToolbar }}
							/>
						)}
					</>
				)
			)}
		</>
	)
}

export default Department
