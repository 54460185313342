import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import Progress from '../Progress'
import { calculateAvgQuarterScoreByProject } from './functions'

const CELL = loader('./graphql/cell.graphql')

const MISSION = loader('./graphql/mission.graphql')

const LineGraphTwo = ({
	departmentId,
	missionCode,
	cellCode,
	koboDeploymentArray,
	projectCode,
}) => {
	const [projectArr, setProjectArr] = useState()
	const { t } = useTranslation()
	const labels = [
		t('average_Q1'),
		t('average_Q2'),
		t('average_Q3'),
		t('average_Q4'),
	]

	const generateRandomColor = () => {
		const letters = '0123456789ABCDEF'
		let color = '#'
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)]
		}
		return color
	}

	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		},
	}
	const data = {
		labels,
		datasets: [],
	}

	const [
		missionData,
		{ data: { mission = null } = {}, loading: missionLoading },
	] = useLazyQuery(MISSION)

	const [cellData, { data: { cell = null } = {}, loading: cellLoading }] =
		useLazyQuery(CELL)

	useEffect(() => {
		if (missionCode && missionCode !== 'all missions') {
			missionData({
				variables: { code: missionCode },
			})
		} else if (cellCode && cellCode !== 'all cells') {
			cellData({ variables: { code: cellCode } })
		}
	}, [missionCode, cellCode])

	useEffect(() => {
		if (cell) {
			setProjectArr(cell.projects)
		}
		if (mission) {
			setProjectArr(mission.projects)
		}
	}, [cell, mission])

	if (missionLoading || cellLoading) return <Progress />

	return (
		<>
			{projectArr && (
				<>
					<h3>{t('evolution_avg_quarterly_by_project')}</h3>
					<Line
						data={{
							...data,
							datasets: projectArr.map(({ id, code }, i) => {
								const color = generateRandomColor()
								return {
									key: i,
									label: code,
									data: labels.map((quarter, i) =>
										calculateAvgQuarterScoreByProject({
											quarter: i + 1,
											selectedDepartmentId:
												departmentId === 'all departments'
													? null
													: Number(departmentId),
											projectCode: code,
											koboDeploymentArray,
										})
									),
									fill: false,
									backgroundColor: color,
									borderColor: color,
								}
							}),
						}}
						options={options}
					/>
				</>
			)}
		</>
	)
}

export default LineGraphTwo
