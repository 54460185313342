import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
// import Moment from 'react-moment'
// import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
// import { Button } from '@mui/material'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
// import useStyles from '../UpdateCheck/useStyles'
import Answer from '../Answer'
import './styles.css'

const KOBO_ANSWER_API = loader(
	'./graphql/koboAnswerApiByKoboDeployment.graphql'
)

const ReceivedChecks = () => {
	const { uid } = useParams()

	const currentUser = useCurrentUser()
	// const { t } = useTranslation()
	const language = window.localStorage.i18nextLng
	// const { btn, link } = useStyles()

	const { data: { koboAnswerApiByKoboDeployment = null } = {}, loading } =
		useQuery(KOBO_ANSWER_API, {
			variables: {
				koboDeploymentUid: uid,
			},
		})

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<Header />
			{loading ? (
				<Progress />
			) : (
				koboAnswerApiByKoboDeployment && (
					<>
						<h1>
							{language === 'en'
								? koboAnswerApiByKoboDeployment[0].koboDeployment.check.label
								: koboAnswerApiByKoboDeployment[0].koboDeployment.check.labelFr}
						</h1>
						<Answer
							answers={koboAnswerApiByKoboDeployment
								.map(({ answers }) => answers)
								.flat()}
						/>
						{/* <h2>
							{t('hello')}, {t('you_have_submitted')}{' '}
							{koboAnswerApiByKoboDeployment.length} {t('sample')}
							{koboAnswerApiByKoboDeployment.length > 1 && 's'}
						</h2>
						<div className="container">
							{koboAnswerApiByKoboDeployment.map(
								({
									createdAt,
									id,
									correctiveMeasures,
									score,
									koboDeployment,
								}) => {
									const { check, project } = koboDeployment
									return (
										<div key={id} className="info-box">
											<div className="left-box">
												<div>
													<h4>{t('submit_date')}:</h4>
													<p>
														<Moment format="DD-MM-YYYY">
															{Date(Number(createdAt))}
														</Moment>
													</p>
												</div>
												<div>
													<h4>{t('check')}:</h4>
													<p>
														{language === 'fr' ? check.labelFr : check.label}
													</p>
												</div>
												<div>
													<h4>{t('corrective_measures_score')}:</h4>{' '}
													<p>{score < 0 ? 'N/A' : score}</p>
												</div>
												<div>
													<h4>{t('project_code')}:</h4> <p>{project.code}</p>
												</div>
											</div>
											<div>
												<div className="right-box">
													<Link to={`/answers/${id}`} className={link}>
														<Button
															className={btn}
															type="submit"
															variant="contained"
															color="secondary"
														>
															{t('view_answers')}
														</Button>
													</Link>
												</div>
											</div>
										</div>
									)
								}
							)}
						</div> */}
					</>
				)
			)}
		</>
	)
}

export default ReceivedChecks
