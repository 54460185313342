import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import {
	Checkbox,
	MenuItem,
	InputLabel,
	Select,
	ListItemText,
} from '@mui/material'
import { StyledFormControl } from './useStyles'
import './styles.css'

const ADD_PROJECTS_TO_BATCH = loader('./graphql/addProjectsToBatch.graphql')
const BATCHES = loader('./graphql/batches.graphql')
const PROJECT = loader('./graphql/project.graphql')
const REMOVE_PROJECTS_FROM_BATCH = loader(
	'./graphql/removeProjectsFromBatch.graphql'
)

const CheckboxesTags = ({ projectCode }) => {
	const [projectAddedOrRemoved, setProjectAddedOrRemoved] = useState(false)
	const [batchArr, setBatchArr] = useState([])
	const { t } = useTranslation()
	const { data: { batches = null } = {}, loading } = useQuery(BATCHES)
	const {
		data: { project = null } = {},
		loading: projectLoading,
		refetch,
	} = useQuery(PROJECT, {
		variables: {
			code: projectCode,
		},
	})
	const [addProjectsToBatch, { loading: addLoading }] = useMutation(
		ADD_PROJECTS_TO_BATCH
	)
	const [removeProjectsFromBatch, { loading: removeLoading }] = useMutation(
		REMOVE_PROJECTS_FROM_BATCH
	)

	const handleProjectsToBatch = ({ target: { value } }) => {
		const lastElementInArr = value[value.length - 1]
		//Find if project already has been added to this batch or not
		const findBatch = Boolean(
			project.batches.find(({ id }) => id === lastElementInArr)
		)
		//Remove this project from batch if found
		if (findBatch) {
			removeProjectsFromBatch({
				variables: {
					input: {
						projectCodes: [projectCode],
						id: lastElementInArr,
					},
				},
			})
			setProjectAddedOrRemoved(true)
		} else {
			const addedBatch = batches.find(({ id }) => id === lastElementInArr)
			setBatchArr([...batchArr, addedBatch])
			addProjectsToBatch({
				variables: {
					input: {
						projectCodes: [projectCode],
						id: lastElementInArr,
					},
				},
			})
			setProjectAddedOrRemoved(true)
		}
	}

	useEffect(() => {
		if (project && projectLoading === false) {
			setBatchArr(project.batches)
		}
	}, [project, projectLoading])

	useEffect(() => {
		if (projectAddedOrRemoved) {
			refetch()
			setProjectAddedOrRemoved(false)
		}
	}, [projectAddedOrRemoved])

	if (loading || projectLoading) return null
	return (
		project && (
			<div className="rightDropDownContainer">
				<StyledFormControl variant="outlined" required color="secondary">
					<InputLabel id="batchLabel">{t('group')}</InputLabel>
					<Select
						onChange={handleProjectsToBatch}
						labelId="InputLabel"
						name="batch"
						multiple
						value={
							project.batches.length
								? project.batches.map(({ name }) => name)
								: []
						}
						renderValue={(selected) => selected.join(', ')}
					>
						{batches.length &&
							batches.map(({ name, id }) => {
								return (
									<MenuItem key={id} value={id}>
										<Checkbox
											checked={
												batchArr.length
													? Boolean(batchArr.find((batch) => batch.id === id))
													: false
											}
										/>

										<ListItemText primary={name} />
									</MenuItem>
								)
							})}
					</Select>
				</StyledFormControl>
			</div>
		)
	)
}

export default CheckboxesTags
