import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import './styles.css'

const NewsFeed = ({ checks }) => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	// const language = window.localStorage.i18nextLng
	const today = new Date()
	const months = [
		t('january'),
		t('february'),
		t('march'),
		t('april'),
		t('may'),
		t('june'),
		t('july'),
		t('august'),
		t('september'),
		t('october'),
		t('november'),
		t('december'),
	]

	const sortedChecksByLatest = checks
		.slice()
		.sort((a, b) => b.createdAt - a.createdAt)
	const receivedForLastMonth = sortedChecksByLatest.find(
		({ status, createdAt }) =>
			status === 'received' &&
			(today.getMonth() - 1 === new Date(createdAt).getMonth() ||
				today.getMonth() - 2 === new Date(createdAt).getMonth())
	)

	const pendingForThisMonth = sortedChecksByLatest.filter(
		({ status, createdAt }) =>
			status === 'sent' &&
			`${today.getMonth()}-${today.getFullYear()}` ===
				`${new Date(createdAt).getMonth()}-${new Date(createdAt).getFullYear()}`
	)
	const pendingFromLastMonth = sortedChecksByLatest.filter(
		({ status, createdAt }) =>
			status === 'sent' &&
			today.getMonth() - 1 === new Date(createdAt).getMonth()
	)
	return (
		<>
			{pendingForThisMonth.length ? (
				<div className="newsfeed-container">
					<p>
						{months[new Date().getMonth()]} {new Date().getFullYear()}{' '}
						{t('checks_are_out')}!! {t('checks_home_page_msg')}{' '}
						<Link to={`/period-checks/${currentUser.project.code}`}>
							{t('here')}
						</Link>{' '}
						{/* <span>
							{pending.length === 1
								? `${t('one')} ${t('check')}`
								: `${pending.length} ${t('checks')}`}{' '}
						</span>
						{pending.length === 1 ? t('is') : t('are')}{' '}
						<span>{t('pending')}</span> */}
					</p>
				</div>
			) : null}
			{pendingFromLastMonth.length ? (
				<div className="newsfeed-container">
					<p>
						<span>!!!{months[new Date().getMonth() - 1]}</span>{' '}
						{t('checks_are_still_pending')}
					</p>
				</div>
			) : null}
			{receivedForLastMonth && (
				<div className="newsfeed-container">
					<p>
						***{t('great_job')}! {t('checks_of_month')}{' '}
						{months[new Date().getMonth() - 2]} {t('have_been_completed')}
					</p>
				</div>
			)}
		</>
	)
}

export default NewsFeed
