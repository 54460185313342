import React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useTranslation } from 'react-i18next'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { Paper, Select, MenuItem } from '@mui/material'

const UPDATE_CORRECTIVE_MEASURES = loader(
	'./graphql/updateCorrectiveMeasures.graphql'
)

const DataGridComponent = ({ rows }) => {
	const { t } = useTranslation()
	const statusArr = ['pending', 'completed']

	const columns = [
		{
			field: 'mission',
			headerName: t('mission'),
			valueGetter: ({ row }) => row.koboDeployment.project.mission.name,
			flex: 1,
		},
		{
			field: 'project',
			headerName: t('project'),
			valueGetter: ({ row }) => row.koboDeployment.project.name,

			flex: 1,
		},
		{
			field: 'corrective measures',
			headerName: t('corrective_measures'),
			renderCell: ({ row }) => {
				return (
					<Link
						to={`/corrective-measures/${row.koboDeployment.deployedFormUid}`}
					>
						{row.requiredMeasures}
					</Link>
				)
			},
			flex: 2,
		},
		{
			field: 'functionInCharge',
			headerName: t('in_charge'),
			valueGetter: ({ row }) => row.functionInCharge,
			flex: 1,
		},
		{
			field: 'dueDate',
			headerName: t('due_date'),
			renderCell: ({ row }) => {
				return row.dueDate ? (
					<Moment format="DD-MM-YYYY">{new Date(Number(row.dueDate))}</Moment>
				) : (
					'-'
				)
			},
			flex: 1,
		},
		{
			field: 'status',
			headerName: t('status'),
			renderCell: ({ row }) => {
				loading ? (
					<span>Loading ...</span>
				) : //If status === 'overdue' user can not update the status
				row.status === 'overdue' ? (
					<span>{t('overdue')}</span>
				) : (
					<Select
						labelId="InputLabel"
						name="status"
						color="secondary"
						disabled={row.status === 'overdue'}
						defaultValue={row.status}
						onChange={({ target: { value } }) => {
							return updateCorrectiveMeasures({
								variables: {
									input: {
										status: value,
										id: row.id,
									},
								},
							})
						}}
					>
						{statusArr.map((element, i) => (
							<MenuItem key={i} value={element}>
								{t(element)}
							</MenuItem>
						))}
					</Select>
				)
			},

			flex: 1,
		},
	]

	const [updateCorrectiveMeasures, { loading }] = useMutation(
		UPDATE_CORRECTIVE_MEASURES
	)

	return (
		<Paper
			style={{ width: '100%', height: rows.length > 20 ? 500 : 'autoHeight' }}
		>
			<DataGridPro
				autoHeight={rows.length < 20}
				columns={columns}
				rows={rows}
				components={{ Toolbar: GridToolbar }}
			/>
		</Paper>
	)
}
export default DataGridComponent
