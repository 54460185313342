/**
 * Mission Component
 *
 * The Mission component allows coordinators to view and manage projects within their mission. It provides a user interface for coordinators to filter and work with projects specific to their mission.
 *
 * @returns {JSX} A component that facilitates project management for coordinators within a specific mission.
 */
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useCurrentUser } from '../../CurrentUserContext'
import Header from '../../UI/Header'
import FilterByProjects from '../../UI/FilterByProjects'

const Mission = () => {
	const { code } = useParams()
	const currentUser = useCurrentUser()
	const isCoordo =
		currentUser && currentUser.groups.find(({ group }) => group === 'coordo')

	if (!currentUser || !isCoordo) return <Navigate to="/" />

	return (
		<>
			<Header />
			{currentUser && currentUser.project && (
				<>
					<FilterByProjects missionCode={code} isCoordo={isCoordo} />
				</>
			)}
		</>
	)
}

export default Mission
