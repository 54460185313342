import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useCurrentUser } from '../../CurrentUserContext'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import { filterKoboDeploymentsByDateAndStatus } from '../../functions'
import { StyledPaper } from './useStyles'

const CheckTable = ({
	koboDeploymentArray,
	selectedStatus,
	fromDate,
	toDate,
}) => {
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const columns = [
		{
			field: 'name',
			headerName: t('name'),
			valueGetter: ({ row }) =>
				language === 'fr' ? row.check.labelFr : row.check.label,
			flex: 1.5,
		},
		{
			field: 'createdAt',
			headerName: currentUser?.project ? t('receive_date') : t('send_date'),
			renderCell: ({ row }) => {
				return (
					<Moment format="DD-MM-YYYY">{new Date(Number(row.createdAt))}</Moment>
				)
			},
			flex: 1,
		},

		{
			field: 'link',
			headerName: t('link'),
			renderCell: ({ row }) => {
				const { status } = row
				return status === 'received' ? (
					<Link to={`/receivedChecks/${row.deployedFormUid}`}>
						{t('view_answers')}
					</Link>
				) : status === 'overdue' || status === 'canceled' ? (
					<span>{t('archived')}</span>
				) : (
					<a target="_blank" rel="noreferrer" href={row.link}>
						{t('check_form')}
					</a>
				)
			},

			flex: 1,
		},
		{
			field: 'status',
			headerName: t('status'),
			valueGetter: ({ row }) => {
				const { status } = row
				return status === 'sent'
					? t('pending')
					: status === 'overdue'
					? t('overdue')
					: status === 'canceled'
					? t('canceled')
					: status === 'received'
					? t('submitted')
					: ''
			},

			flex: 1,
		},

		{
			field: 'comment',
			headerName: t('comment'),
			valueGetter: ({ row }) => row?.comment,
			flex: 1,
		},
	]

	return (
		<StyledPaper>
			{koboDeploymentArray && (
				<DataGridPro
					autoHeight
					columns={columns}
					rows={filterKoboDeploymentsByDateAndStatus({
						koboDeploymentArray,
						selectedStatus,
						fromDate,
						toDate,
					})}
					components={{ Toolbar: GridToolbar }}
				/>
			)}
		</StyledPaper>
	)
}

export default CheckTable
