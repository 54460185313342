//This function to handle the calculating of the average score per quarter
//Note: use UTC time like the server, not local time from the client, to avoid time zone issues (e.g. 01-Jan AM on server may still be 31-Dec PM on client!)

const getQuarter = (d) => {
	return Math.floor(d.getUTCMonth() / 3 + 1)
}

const calculateAvgQuarterScore = ({
	quarter,
	selectedDepartmentId,
	koboDeploymentArray,
}) => {
	if (koboDeploymentArray.length) {
		//Filter so we exclude cancelled deployments and other department deployments and has a positive score
		const filterCancelledAndDepartment = koboDeploymentArray.filter(
			({ status, check, score }) =>
				status !== 'canceled' &&
				check.category.department.id === selectedDepartmentId &&
				score > -1 &&
				typeof score === 'number'
		)

		//Filter the array by the selected quarter
		const selectedQuarterKoboDeploymentsScores = filterCancelledAndDepartment
			.filter(({ createdAt }) => getQuarter(new Date(createdAt)) === quarter)
			.map(({ score }) => score)

		if (selectedQuarterKoboDeploymentsScores.length) {
			return parseInt(
				selectedQuarterKoboDeploymentsScores.reduce((a, b) => a + b) /
					selectedQuarterKoboDeploymentsScores.length
			)
		}
		return null
	}
}

export default calculateAvgQuarterScore
