import { styled } from '@mui/material/styles'
import { Button, FormControl, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(() => ({
	width: '50%',
	marginBottom: '20px',
}))

export const StyledButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
}))

export const StyledFormControl = styled(FormControl)(() => ({
	color: '#35463D',
	borderColor: 'red',
	minWidth: 250,
	'& label': {
		backgroundColor: '#F9F9F9',
		paddingLeft: 5,
		paddingRight: 5,
	},
	'& label.Mui-focused': {
		color: '#35463D',
	},
}))

export const StyledTextFieldInput = styled(TextField)(() => ({
	marginRight: '15px',
}))
