/**
 * ManageProjects Component
 *
 * The ManageProjects component provides a user interface for managing projects. It allows users to add new projects and assign projects to groups, such as cells and missions.
 *
 * @returns {JSX} A component that enables project management, including adding new projects and assigning them to groups.
 */
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'

import Header from '../../UI/Header'
import AddBatchBox from '../../UI/AddBatchBox'
import CellAndMission from '../../UI/FilterByCellOrMission/CellAndMission'

const ManageProjects = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<h2>{t('manage_projects')}</h2>
			<AddBatchBox />
			<div style={{ marginTop: '60px' }}>
				<h2>{t('assign_projects_to_groups')}</h2>
				<CellAndMission />
			</div>
		</>
	)
}

export default ManageProjects
