import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

import {
	Alert,
	Box,
	MenuItem,
	Select,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Stack,
	Typography,
} from '@mui/material'
import Progress from '../Progress'
import oooImage from '../../../images/ooo.png'
import {
	StyledButton,
	StyledFormControl,
	StyledTable,
	StyledTableCell,
	StyledTableContainer,
} from './useStyles'

const TOGGLE_DEPARTMENT_OUT_OF_OFFICE = loader(
	'./graphql/toggleDepartmentInProjectOutOfOffice.graphql'
)

const USER_BY_PROJECT_AND_DEPARTMENT = loader(
	'./graphql/userByProjectAndDepartment.graphql'
)

const OooTable = ({ userId, showEmail, departmentId, projectCode }) => {
	const today = new Date()
	const monthsByQuarter = [
		[
			{ month: 'january', lastDay: 31 },
			{ month: 'february', lastDay: 28 },
			{ month: 'march', lastDay: 31 },
		],
		[
			{ month: 'april', lastDay: 30 },
			{ month: 'may', lastDay: 31 },
			{ month: 'june', lastDay: 30 },
		],
		[
			{ month: 'july', lastDay: 31 },
			{ month: 'august', lastDay: 31 },
			{ month: 'september', lastDay: 30 },
		],
		[
			{ month: 'october', lastDay: 31 },
			{ month: 'november', lastDay: 30 },
			{ month: 'december', lastDay: 31 },
		],
	]

	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const currentQuarter = Math.floor(new Date().getMonth() / 3 + 1)
	const handleFilteringFromMonths = ({ threeMonths }) => {
		//Getting the current month from monthsByQuarter
		const currentMonth = monthsByQuarter.flat()[today.getMonth()]
		//Find what months should be returned in a quarter
		/**
		 * For example in Feb, `From` drop down menue should return "Jan, Feb"
		 * In March we should return "Jan, Feb, Mar". In April we should return April only etc
		 */
		const monthsBeforeCurrentInQuarter = threeMonths.slice(
			0,
			threeMonths.indexOf(currentMonth)
		)
		//Return the previous months in a quarter plus the current month in this quarter
		return [...monthsBeforeCurrentInQuarter, currentMonth]
	}
	const [fromMonthsArr, setFromMonthsArr] = useState(() =>
		handleFilteringFromMonths({
			threeMonths: monthsByQuarter[currentQuarter - 1],
		})
	)

	const [toMonthsArr, setToMonthsArr] = useState(
		monthsByQuarter[currentQuarter - 1]
	)

	const [userDepartment, setUserDepartment] = useState(null)

	const [toggleDepartmentInProjectOutOfOffice, { data = null, loading }] =
		useMutation(TOGGLE_DEPARTMENT_OUT_OF_OFFICE)

	const [
		userByProjectAndDepartmentData,
		{
			data: { userByProjectAndDepartment = null } = {},
			loading: userLoading,
			refetch,
		},
	] = useLazyQuery(USER_BY_PROJECT_AND_DEPARTMENT)

	const [values, setValues] = useState(null)

	const handleSubmitOutOfOffice = () => {
		const { outOfOfficeFromDate, inOfficeDate, outOfOffice } = values
		return toggleDepartmentInProjectOutOfOffice({
			variables: {
				input: {
					departmentId,
					outOfOfficeFromDate: outOfOffice ? outOfOfficeFromDate : null,
					inOfficeDate: inOfficeDate === '' ? null : inOfficeDate,
					outOfOffice,
					projectCode,
				},
			},
		})
	}

	useEffect(() => {
		if (data && userByProjectAndDepartment) {
			refetch()
		} else if (data && !userByProjectAndDepartment) {
			window.location.reload(false)
		}
	}, [data])

	useEffect(() => {
		if (
			departmentId &&
			projectCode &&
			projectCode !== 'all' &&
			departmentId !== 'all'
		) {
			userByProjectAndDepartmentData({
				variables: {
					projectCode,
					departmentId,
				},
			})
		}
	}, [departmentId, projectCode])

	useEffect(() => {
		const regex = /^\d{13}$/ // regular expression to match the "1682812800000" timestamp format
		//If the user is setting his own out of office
		if (userId && departmentId) {
			const currentUserDepartment = currentUser.departments.find(
				({ id }) => id === departmentId
			)
			setUserDepartment(currentUserDepartment)
			setValues({
				...values,
				outOfOffice: currentUserDepartment?.outOfOffice,
				outOfOfficeFromDate: regex.test(
					currentUserDepartment?.outOfOfficeFromDate
				)
					? new Date(Number(currentUserDepartment?.outOfOfficeFromDate))
							.toISOString()
							.substring(0, 10)
					: currentUserDepartment?.outOfOfficeFromDate,
				inOfficeDate: regex.test(currentUserDepartment?.inOfficeDate)
					? new Date(Number(currentUserDepartment?.inOfficeDate))
							.toISOString()
							.substring(0, 10)
					: currentUserDepartment?.inOfficeDate,
			})
		}
		if (userByProjectAndDepartment) {
			const userDepartment = userByProjectAndDepartment.departments.find(
				({ id }) => id === departmentId
			)
			setUserDepartment(userDepartment)
			setValues({
				...values,
				outOfOffice: userDepartment?.outOfOffice,
				outOfOfficeFromDate: regex.test(userDepartment?.outOfOfficeFromDate)
					? new Date(Number(userDepartment?.outOfOfficeFromDate))
							.toISOString()
							.substring(0, 10)
					: userDepartment?.outOfOfficeFromDate,
				inOfficeDate: regex.test(userDepartment?.inOfficeDate)
					? new Date(Number(userDepartment?.inOfficeDate))
							.toISOString()
							.substring(0, 10)
					: userDepartment?.inOfficeDate,
			})
		}
	}, [currentUser, userByProjectAndDepartment, userId, departmentId])

	if (userLoading) return <Progress />

	return (
		<>
			{data?.toggleDepartmentInProjectOutOfOffice.outOfOffice ? (
				<Stack sx={{ width: '70%', margin: 'auto' }} spacing={2}>
					<Alert severity="success">{t('success_request_msg')}</Alert>
				</Stack>
			) : null}
			{
				<Box>
					<h1>{t('manage_out_of_office')}</h1>
					<Typography variant="h5">{t('mark_ooo_msg')}</Typography>
					<Typography variant="body1" style={{ marginTop: '30px' }}>
						{t('non_inOfficeDate_msg')}
						<br />
						<img src={oooImage} alt="ooo" />
					</Typography>
				</Box>
			}
			{(userId || userByProjectAndDepartment) && values && (
				<StyledTableContainer component={Paper}>
					<StyledTable stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{showEmail && userDepartment && (
									<StyledTableCell>{t('department')}</StyledTableCell>
								)}
								<StyledTableCell>{t('present')}</StyledTableCell>
								<StyledTableCell>{t('from')}</StyledTableCell>
								<StyledTableCell>{t('to')}</StyledTableCell>
								<StyledTableCell></StyledTableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							<TableRow hover>
								{userDepartment && <TableCell>{userDepartment.name}</TableCell>}
								<TableCell>
									<StyledFormControl
										variant="outlined"
										required
										color="secondary"
									>
										<Select
											labelId="InputLabel"
											name="department"
											defaultValue={
												values.outOfOffice ? 'not present' : 'present'
											}
											onChange={({ target: { value } }) =>
												value === 'present'
													? setValues({ ...values, outOfOffice: false })
													: setValues({ ...values, outOfOffice: true })
											}
										>
											{[
												{ value: 'present', text: 'present' },
												{ value: 'not present', text: 'not_present' },
											].map((el) => (
												<MenuItem key={el.value} value={el.value}>
													{t(el.text)}
												</MenuItem>
											))}
										</Select>
									</StyledFormControl>
								</TableCell>
								<TableCell>
									<StyledFormControl
										variant="outlined"
										required
										color="secondary"
									>
										<input
											style={{
												height: '50px',
											}}
											label={t('date')}
											type="date"
											value={values.outOfOfficeFromDate || ''}
											disabled={!values.outOfOffice}
											onChange={({ target }) =>
												setValues({
													...values,
													outOfOfficeFromDate: target.value,
												})
											}
										/>

										{/* <Select
											labelId="InputLabel"
											name="from"
											disabled={!values.outOfOffice}
											defaultValue={
												values.outOfOfficeFromDate
													? monthsByQuarter.flat()[
															new Date(
																Number(values.outOfOfficeFromDate)
															).getMonth()
													  ].month
													: // userId && currentUser.outOfOfficeFromDate
													  // 	? monthsByQuarter.flat()[
													  // 			new Date(
													  // 				Number(currentUser.outOfOfficeFromDate)
													  // 			).getMonth()
													  // 	  ].month
													  // 	: userByProjectAndDepartment &&
													  // 	  userByProjectAndDepartment.outOfOfficeFromDate
													  // 	? monthsByQuarter.flat()[
													  // 			new Date(
													  // 				Number(
													  // 					userByProjectAndDepartment.outOfOfficeFromDate
													  // 				)
													  // 			).getMonth()
													  // 	  ].month
													  ''
											}
											onChange={({ target: { value } }) => {
												const chosenMonthIndex = monthsByQuarter
													.flat()
													.findIndex((x) => x.month === value)
												setToMonthsArr(monthsByQuarter[currentQuarter - 1])

												setValues({
													...values,
													outOfOfficeFromDate: `${today.getFullYear()}-${
														chosenMonthIndex + 1
													}-01`,
												})
											}}
										>
											{fromMonthsArr.map((el, i) => (
												<MenuItem key={`${el.month}${i}`} value={el.month}>
													01 {t(el.month)}
												</MenuItem>
											))}
										</Select> */}
									</StyledFormControl>
								</TableCell>
								<TableCell>
									<StyledFormControl
										variant="outlined"
										required
										color="secondary"
									>
										<input
											style={{
												height: '50px',
											}}
											label={t('date')}
											type="date"
											value={values.inOfficeDate || ''}
											disabled={!values.outOfOffice}
											onChange={({ target }) =>
												setValues({
													...values,
													inOfficeDate: target.value,
												})
											}
											//Disable future dates
											min={values.outOfOfficeFromDate}
										/>

										{/* <Select
											labelId="InputLabel"
											name="to"
											disabled={!values.outOfOffice}
											defaultValue={
												values.inOfficeDate
													? monthsByQuarter.flat()[
															new Date(
																Number(currentUser.inOfficeDate)
															).getMonth()
													  ].month
													: // userId && currentUser.inOfficeDate
													  // 	? monthsByQuarter.flat()[
													  // 			new Date(
													  // 				Number(currentUser.inOfficeDate)
													  // 			).getMonth()
													  // 	  ].month
													  // 	: userByProjectAndDepartment &&
													  // 	  userByProjectAndDepartment.inOfficeDate
													  // 	? monthsByQuarter.flat()[
													  // 			new Date(
													  // 				Number(userByProjectAndDepartment.inOfficeDate)
													  // 			).getMonth()
													  // 	  ].month
													  ''
											}
											onChange={({ target: { value } }) => {
												const chosenMonthIndex = monthsByQuarter
													.flat()
													.findIndex((x) => x.month === value)

												setValues({
													...values,
													inOfficeDate: `${today.getFullYear()}-${
														chosenMonthIndex + 1
													}-${
														monthsByQuarter.flat()[chosenMonthIndex].lastDay
													}`,
												})
											}}
										>
											{toMonthsArr.map((el, i) => (
												<MenuItem key={`${el.month}${i}`} value={el.month}>
													{el.lastDay} {t(el.month)}
												</MenuItem>
											))}
										</Select> */}
									</StyledFormControl>
								</TableCell>
								<TableCell>
									<StyledButton
										type="submit"
										variant="contained"
										color="secondary"
										onClick={handleSubmitOutOfOffice}
									>
										{loading ? '...' : t('save')}
									</StyledButton>
								</TableCell>
							</TableRow>
						</TableBody>
					</StyledTable>
				</StyledTableContainer>
			)}
			{/*Just display an info message if there is no user assigned to this department in this project*/}
			{(!userId && !userByProjectAndDepartment) && (<><p><b><i>{t('no_user_assigned_department_project')}</i></b></p></>)}
		</>
	)
}

export default OooTable
