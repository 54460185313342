/**
 * UpdateCheck Component
 *
 * The `UpdateCheck` component is a React component used for updating check-related information.
 *
 * @return {JSX.Element} A component for updating the send date and send period of a check.
 */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import { InputAdornment } from '@mui/material'
import { Check } from '@mui/icons-material'
import CheckToBatches from '../../UI/CheckToBatches'
import Progress from '../../UI/Progress'
import Header from '../../UI/Header'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'

import { StyledButton, StyledTextField } from './useStyles'
import './styles.css'

const CHECK = loader('./graphql/check.graphql')
const UPDATE_CHECK_SEND_PERIOD = loader(
	'./graphql/updateCheckSendPeriod.graphql'
)
const UPDATE_CHECK_SEND_DATE = loader('./graphql/updateCheckSendDate.graphql')

const UpdateCheck = () => {
	const { name } = useParams()
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	// Check if the user is admin
	const isSuperAdmin = currentUser?.groups.find(
		({ group }) => group === 'superAdmin'
	)
	const [pastDateError, setPastDateError] = useState(false)
	const today = new Date()

	const [updateCheckSendPeriod, { data: updatePeriodData = {} }] = useMutation(
		UPDATE_CHECK_SEND_PERIOD
	)
	const [updateCheckSendDate, { data: updateDateData = {} }] = useMutation(
		UPDATE_CHECK_SEND_DATE
	)

	const { data: { check = null } = {}, loading: checkLoading } = useQuery(
		CHECK,
		{
			variables: {
				name,
			},
		}
	)

	const disablePastDate = (e) => {
		const chosenDate = new Date(e.target.value)
		if (chosenDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
			return setPastDateError(false)
		} else {
			return setPastDateError(true)
		}
	}

	const handleUpdateDate = (e) => {
		e.preventDefault()
		const { value } = e.target.date
		return updateCheckSendDate({
			optimisticResponse: {
				updateCheckSendDate: {
					check: { ...check },
					__typename: 'UpdateCheckSendDatePayload',
				},
			},
			update: (cache) => {
				const { check } = cache.readQuery({
					query: CHECK,
					variables: {
						name,
					},
				})
				cache.writeQuery({
					query: CHECK,
					data: {
						check: {
							...check,
							sendDate: value,
						},
					},
				})
			},
			variables: {
				input: {
					name,
					sendDate: value,
				},
			},
		})
	}

	const handleUpdatePeriod = (e) => {
		e.preventDefault()
		const { value } = e.target.period
		return updateCheckSendPeriod({
			optimisticResponse: {
				updateCheckSendPeriod: {
					check: { ...check },
					__typename: 'UpdateCheckSendPeriodPayload',
				},
			},
			update: (cache) => {
				const { check } = cache.readQuery({
					query: CHECK,
					variables: {
						name,
					},
				})
				cache.writeQuery({
					query: CHECK,
					data: {
						check: {
							...check,
							sendPeriod: Number(value),
						},
					},
				})
			},
			variables: {
				input: {
					name,
					sendPeriod: Number(value),
				},
			},
		})
	}
	// Ensure the user is authenticated or user is an admin
	if (!currentUser || !isSuperAdmin) return <Navigate to="/" />
	return (
		<>
			{/* Display the application header */}
			<Header />
			{checkLoading ? (
				<Progress />
			) : (
				check && (
					<>
						{/* Render the check name and batches */}
						<h1>{language === 'fr' ? check.labelFr : check.label}</h1>
						<div>
							{/* Form for updating check send period */}
							<CheckToBatches checkBatches={check.batches} checkName={name} />
							<h2>{t('check_date_period')}</h2>
							<div className="formContainer">
								<form
									noValidate
									autoComplete="off"
									onSubmit={handleUpdatePeriod}
									className="form"
								>
									<StyledTextField
										defaultValue={check.sendPeriod || 0}
										id="outlined-basic"
										label={`${t('period')}/${t('month')}`}
										variant="outlined"
										name="period"
										type="number"
										color="secondary"
										InputProps={{
											startAdornment: updatePeriodData &&
												updatePeriodData.updateCheckSendPeriod && (
													<InputAdornment position="start">
														<Check />
													</InputAdornment>
												),
										}}
										onChange={(event) =>
											event.target.value < 0
												? (event.target.value = 0)
												: event.target.value
										}
									/>
									<StyledButton
										disabled={Boolean(
											updatePeriodData && updatePeriodData.updateCheckSendPeriod
										)}
										type="submit"
										variant="contained"
										color="secondary"
									>
										{check.sendPeriod ? t('update') : t('save')}
									</StyledButton>
								</form>
								{/* Form for updating check send date */}
								<form
									noValidate
									autoComplete="off"
									onSubmit={handleUpdateDate}
									className="form"
								>
									<StyledTextField
										defaultValue={
											check.sendDate && check.sendDate.substring(0, 10)
										}
										id="outlined-basic"
										label="send date - dd/mm/yyyy"
										variant="outlined"
										name="date"
										type="date"
										color="secondary"
										format="YYYY-MM-DD"
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											startAdornment: updateDateData &&
												updateDateData.updateCheckSendDate && (
													<InputAdornment position="start">
														<Check />
													</InputAdornment>
												),
										}}
										onChange={disablePastDate}
									/>
									<StyledButton
										type="submit"
										variant="contained"
										color="secondary"
										disabled={
											Boolean(
												updateDateData && updateDateData.updateCheckSendDate
											) || Boolean(pastDateError)
										}
									>
										{check.sendDate ? t('update') : t('save')}
									</StyledButton>
								</form>
								{/* Display error message for past date selection */}
								{pastDateError && (
									<p className="errorMsg">{t('future_date_error')}</p>
								)}
							</div>
						</div>
						{/* Provide a link to navigate back to the home page if both date and period are set */}
						{check.sendDate && check.sendPeriod ? (
							<Link className="link" to={`/home`}>
								<StyledButton
									variant="contained"
									color="secondary"
									style={{ marginTop: '60px' }}
								>
									{t('back_home')}
								</StyledButton>
							</Link>
						) : null}
					</>
				)
			)}
		</>
	)
}

export default UpdateCheck
