import React from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import Progress from '../Progress'
import { calculateAvgQuarterScore } from './functions'

const ALL_DEPARTMENTS = loader(
	'../../pages/Department/graphql/allDepartments.graphql'
)

const LineGraphOne = ({ departmentId, koboDeploymentArray }) => {
	const { t } = useTranslation()
	const labels = [
		t('average_Q1'),
		t('average_Q2'),
		t('average_Q3'),
		t('average_Q4'),
	]
	const backgroundColors = [
		'rgba(255, 99, 132, 0.2)',
		'rgba(54, 162, 235, 0.2)',
		'rgba(255, 206, 86, 0.2)',
		'rgba(75, 192, 192, 0.2)',
		'rgba(153, 102, 255, 0.2)',
		'rgba(255, 159, 64, 0.2)',
	]
	const borderColors = [
		'rgba(255, 99, 132, 1)',
		'rgba(54, 162, 235, 1)',
		'rgba(255, 206, 86, 1)',
		'rgba(75, 192, 192, 1)',
		'rgba(153, 102, 255, 1)',
		'rgba(255, 159, 64, 1)',
	]
	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		},
	}
	const data = {
		labels,
		datasets: [],
	}

	const { data: { allDepartments = null } = {}, loading: departmentsLoading } =
		useQuery(ALL_DEPARTMENTS)
	if (departmentsLoading) return <Progress />

	return (
		<>
			{allDepartments && (
				<>
					<h3>{t('evolution_avg_quarterly_by_department')}</h3>
					<Line
						data={{
							...data,
							datasets: allDepartments.map(({ id, name }, i) => {
								return {
									key: i,
									label: name,
									data: labels.map((quarter, i) =>
										calculateAvgQuarterScore({
											quarter: i + 1,
											selectedDepartmentId: Number(id),
											koboDeploymentArray,
										})
									),
									fill: false,
									backgroundColor: backgroundColors[i],
									borderColor: borderColors[i],
								}
							}),
						}}
						options={options}
					/>
				</>
			)}
		</>
	)
}

export default LineGraphOne
