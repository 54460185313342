/**
 * SubmittedView Component
 *
 * The SubmittedView component displays the submitted corrective measures for a task. It includes information such as the status, due date, priority, required measures, and the function in charge.
 *
 * @param {object} correctiveMeasures - An object containing corrective measures data.
 * @returns {JSX} A component that displays submitted corrective measures.
 */
import React from 'react'
import { useTranslation } from 'react-i18next'

const SubmittedView = ({ correctiveMeasures }) => {
	const { t } = useTranslation()
	const { dueDate, priority, requiredMeasures, functionInCharge, status } =
		correctiveMeasures
	return (
		<>
			<h2>{t('submitted_corrective_measures')}</h2>
			<h4>{`${t('status')}: ${status}`}</h4>
			{[
				{
					[t('due_date')]: dueDate
						? new Date(Number(dueDate)).toDateString()
						: '',
				},
				{ [t('priority')]: priority },
				{ [t('corrective_measures_required')]: requiredMeasures },
				{ [t('the_function_in_charge')]: functionInCharge },
			].map((correctiveMeasure, i) => {
				return (
					<div key={i} className="questions-container">
						<div className="left">
							<h3 className="text-q">-</h3>
							<h3>-</h3>
						</div>
						<div className="right">
							<h4>{Object.keys(correctiveMeasure)[0]}</h4>
							<p>{Object.values(correctiveMeasure)[0]}</p>
						</div>
					</div>
				)
			})}
		</>
	)
}

export default SubmittedView
