import { styled } from '@mui/material/styles'
import { Button, TextField, InputAdornment } from '@mui/material'

export const StyledButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
	textTransform: 'none',
}))

export const StyledTextField = styled(TextField)(() => ({
	marginRight: '15px',
	marginLeft: '15px',
}))
