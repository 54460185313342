/**
 * All Checks Component
 *
 * This component displays a list of all checks, providing filters for different views and options.
 * It is designed for authorized users and serves as a dashboard to manage and filter checks.
 * Users can apply filters to view checks by cell, mission, status, and date.
 *
 * @returns {JSX} A JSX component that allows authorized users to filter and view checks based on various criteria.
 */
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'

import Header from '../../UI/Header'
import FilterByCellOrMission from '../../UI/FilterByCellOrMission'

const AllChecks = () => {
	const currentUser = useCurrentUser()
	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<FilterByCellOrMission checkTable={true} />
		</>
	)
}

export default AllChecks
