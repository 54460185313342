import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Link, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import { useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
import { loader } from 'graphql.macro'
import Progress from '../../UI/Progress'
import Header from '../../UI/Header'
import { TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material'
import {
	StyledButton,
	StyledTable,
	StyledTableCell,
	StyledTableContainer,
} from './useStyles'
import './styles.css'

const CHECK = loader('./graphql/check.graphql')

const Questions = () => {
	const { name } = useParams()
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const {
		data: { check = {} } = {},
		loading: checkLoading,
		refetch,
	} = useQuery(CHECK, {
		variables: { name },
	})

	useEffect(() => {
		window.scrollTo(0, 0)
		refetch()
	}, [])

	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			<Header />
			{checkLoading || !check ? (
				<Progress />
			) : (
				<>
					<div>
						{check.sendDate && check.sendPeriod ? null : (
							<Link className="link" to={`/updateCheck/${check.name}`}>
								<StyledButton variant="contained" color="secondary">
									{!Boolean(check.sendDate) &&
										!Boolean(check.sendPeriod) &&
										t('set_send_date_and_period')}
									{Boolean(check.sendDate) &&
										!Boolean(check.sendPeriod) &&
										t('set_period')}
									{!Boolean(check.sendDate) &&
										Boolean(check.sendPeriod) &&
										t('set_date')}
								</StyledButton>
							</Link>
						)}
						<h1>{language === 'fr' ? check.labelFr : check.label} Check</h1>
					</div>
					<div>
						<h4>Department: {check.category.department.name}</h4>
						<h4>
							Category:
							{language === 'fr'
								? check.category.labelFr
								: check.category.label}
						</h4>
					</div>
					<StyledTableContainer component={Paper}>
						<StyledTable stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell>{t('name')}</StyledTableCell>
									<StyledTableCell>{t('label')}</StyledTableCell>
									<StyledTableCell>{t('created_at')}</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{check.questions.map(
									({ id, createdAt, label, labelFr, name }) => {
										return (
											<TableRow hover key={id}>
												<TableCell component="th" scope="row">
													{name}
												</TableCell>
												<TableCell>
													{language === 'fr' ? labelFr : label}
												</TableCell>
												<TableCell>
													<Moment format="DD-MM-YYYY">
														{new Date(Number(createdAt))}
													</Moment>
												</TableCell>
											</TableRow>
										)
									}
								)}
							</TableBody>
						</StyledTable>
					</StyledTableContainer>
				</>
			)}
		</>
	)
}

export default Questions
