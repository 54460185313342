import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { CurrentUserProvider } from './CurrentUserContext'
// Pages
import Department from './pages/Department'
import Home from './pages/Home'
import Profile from './pages/Profile'
import Page404 from './pages/Page404'
import Landing from './pages/Landing'
import Upload from './pages/Upload'
import Footer from './UI/Footer'
import Questions from './pages/Questions'
import UpdateCheck from './pages/UpdateCheck'
import Project from './pages/Project'
import Mission from './pages/Mission'
import AllChecks from './pages/AllChecks'
import ReceivedChecks from './pages/ReceivedChecks'
import Answer from './pages/Answer'
import CorrectiveMeasures from './pages/CorrectiveMeasures'
import ManageProjects from './pages/ManageProjects'
import ManageOutOfOffice from './pages/ManageOutOfOffice'
import ProjectSummary from './pages/ProjectSummary'
import MissionSummary from './pages/MissionSummary'
import PeriodChecks from './pages/PeriodChecks'
import LatestChecks from './pages/LatestChecks'
import Support from './pages/Support'
import Error from './pages/Error'
import AdminControl from './pages/AdminControl'
import SendCheck from './pages/SendCheck'

const App = () => {
	return (
		<div className="App">
			<div id="container">
				<CurrentUserProvider>
					<Routes>
						<Route path="/department" exact element={<Department />} />
						<Route path="/home" exact element={<Home />} />
						<Route path="/profile" exact element={<Profile />} />
						<Route path="/" exact element={<Landing />} />
						<Route path="/upload" exact element={<Upload />} />
						<Route path="/check/:name" element={<Questions />} />
						<Route path="/updateCheck/:name" element={<UpdateCheck />} />
						<Route path="/project/:checkName" element={<Project />} />
						<Route path="/mission/:code" element={<Mission />} />
						<Route path="/allChecks" element={<AllChecks />} />
						<Route path="/receivedChecks/:uid" element={<ReceivedChecks />} />
						<Route path="/answers/:id" element={<Answer />} />
						<Route
							path="/corrective-measures/:koboDeploymentUid"
							element={<CorrectiveMeasures />}
						/>
						<Route path="/manage-projects" element={<ManageProjects />} />
						<Route path="/manage-users" element={<ManageOutOfOffice />} />
						<Route path="/project-summary/:code" element={<ProjectSummary />} />
						<Route path="/mission-summary/:code" element={<MissionSummary />} />
						<Route path="/period-checks/:code" element={<PeriodChecks />} />
						<Route path="/latestChecks/:code" element={<LatestChecks />} />
						<Route path="/support" element={<Support />} />
						<Route path="/error" element={<Error />} />
						<Route path="/admin-control" element={<AdminControl />} />
						<Route path="/sendCheck/:name" element={<SendCheck />} />
						<Route path="*" element={<Page404 />} />
					</Routes>
				</CurrentUserProvider>
			</div>
			<Footer />
		</div>
	)
}

export default App
