import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { Toolbar, IconButton, Drawer, Link } from '@mui/material'
import {
	StyledAppBar,
	StyledToolbar,
	StyledMenuButton,
	StyledLoginButton,
	StyledLoginAccountCircle,
} from './useStyles'
import { Menu as MenuIcon } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'
import msfLogo from '../../../images/msflogo-trans-background.png'
import './styles.css'

const Header = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const isSuperAdmin =
		currentUser &&
		currentUser.groups.find(({ group }) => group === 'superAdmin')
	// const isCoordo =
	// 	currentUser && currentUser.groups.find(({ group }) => group === 'coordo')
	const isHq =
		currentUser && currentUser.groups.find(({ group }) => group === 'hq')
	let headersData = []
	if (currentUser && currentUser.departments.length) {
		if (isSuperAdmin) {
			headersData = [
				{
					label: t('home'),
					href: '/home',
				},
				{
					label: t('department'),
					href: '/department',
				},
				{
					label: t('manage_projects'),
					href: `/manage-projects`,
				},
				{
					label: t('manage_users'),
					href: `/manage-users`,
				},
				{
					label: t('upload'),
					href: '/upload',
				},
				{
					label: t('support'),
					href: '/support',
				},
				{
					label: t('admin'),
					href: '/admin-control',
				},
			]
		} else if (isHq && !isSuperAdmin) {
			headersData = [
				{
					label: t('home'),
					href: '/home',
				},
				{
					label: t('department'),
					href: '/department',
				},
				{
					label: t('support'),
					href: '/support',
				},
			]
		}
		if (currentUser.project) {
			headersData = [
				{
					label: t('home'),
					href: '/home',
				},
				{
					label: t('checks'),
					href: `/latestChecks/${currentUser.project.code}`,
				},

				{
					label: t('period_checks'),
					href: `/period-checks/${currentUser.project.code}`,
				},
				{
					label: t('powerbi_report'),
					href: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=6e2e3d73-1f58-4c43-b9f4-4ea136df5b6f&ctid=4d9dd1af-83ce-4e9b-b090-b0543ccc2b31',
					powerBi: true,
				},
				{
					label: t('support'),
					href: '/support',
				},
			]
		}
	}

	//Remove all local stored values and cookies on logout
	const handleLogOutCleaningSession = () => {
		if (currentUser) {
			localStorage.clear()
			Cookies.remove('connect.sid')
		}
	}

	const [state, setState] = useState({
		mobileView: false,
		drawerOpen: false,
	})

	const { mobileView, drawerOpen } = state

	useEffect(() => {
		const setResponsiveness = () => {
			return window.innerWidth < 900
				? setState((prevState) => ({ ...prevState, mobileView: true }))
				: setState((prevState) => ({ ...prevState, mobileView: false }))
		}

		setResponsiveness()

		window.addEventListener('resize', () => setResponsiveness())
	}, [])

	const displayDesktop = () => {
		return (
			<StyledToolbar>
				{femmecubatorLogo}
				<p className="logoText">WeCheck</p>
				<div className="menuBottonsContainer">{getMenuButtons()}</div>
				{currentUser && (
					<RouterLink to="/profile" className="accountCircleIconLink">
						<StyledLoginAccountCircle />
					</RouterLink>
				)}
				<form
					method={currentUser ? 'post' : 'get'}
					action={currentUser ? '/logout' : '/login'}
				>
					<StyledLoginButton
						variant="contained"
						color="secondary"
						onClick={handleLogOutCleaningSession}
						type="submit"
					>
						{currentUser ? t('sign_out') : t('sign_in')}
					</StyledLoginButton>
				</form>
			</StyledToolbar>
		)
	}

	const displayMobile = () => {
		const handleDrawerOpen = () =>
			setState((prevState) => ({ ...prevState, drawerOpen: true }))
		const handleDrawerClose = () =>
			setState((prevState) => ({ ...prevState, drawerOpen: false }))

		return (
			<Toolbar>
				<IconButton
					{...{
						edge: 'start',
						color: '#FD0000',
						'aria-label': 'menu',
						'aria-haspopup': 'true',
						onClick: handleDrawerOpen,
					}}
				>
					<MenuIcon />
				</IconButton>

				<Drawer
					{...{
						anchor: 'left',
						open: drawerOpen,
						onClose: handleDrawerClose,
					}}
				>
					<div className="drawerContainer">{getDrawerChoices()}</div>
				</Drawer>

				<div>{femmecubatorLogo}</div>
			</Toolbar>
		)
	}

	const getDrawerChoices = () => {
		return headersData.map(({ label, href, powerBi }, i) => {
			return (
				<Link
					target={powerBi ? '_blank' : ''}
					rel={powerBi ? 'noreferrer' : ''}
					to={href}
					key={i}
					color="inherit"
					style={{ textDecoration: 'none', fontWeight: 'bold' }}
				>
					<StyledMenuButton>{label}</StyledMenuButton>
				</Link>
			)
		})
	}

	const femmecubatorLogo = (
		<a href="/">
			<img src={msfLogo} alt="msfLogo" className="logo" />
		</a>
	)

	const getMenuButtons = () => {
		return headersData.map(({ label, href, powerBi }, i) => {
			return (
				<StyledMenuButton
					target={powerBi ? '_blank' : ''}
					rel={powerBi ? 'noreferrer' : ''}
					to={href}
					key={i}
					color="inherit"
					component={RouterLink}
					style={{ textDecoration: 'none', fontWeight: 'bold' }}
				>
					{label}
				</StyledMenuButton>
			)
		})
	}

	return (
		<header>
			<StyledAppBar>
				{mobileView ? displayMobile() : displayDesktop()}
			</StyledAppBar>
		</header>
	)
}

export default Header
