/**
 * Home Component
 *
 * The Home component is the main page of the application, displayed to logged-in users. It provides different views based on the user's role and permissions.
 *
 * @returns {JSX} A component that serves as the main page of the application for logged-in users, providing various views based on user roles and permissions.
 */
import React, { useEffect } from 'react'
import { useCurrentUser } from '../../CurrentUserContext'
import { Navigate } from 'react-router-dom'
import { Container } from '@mui/material'
import Header from '../../UI/Header'
import FilterByCellOrMission from '../../UI/FilterByCellOrMission'
import FilterByProjects from '../../UI/FilterByProjects'

import './styles.css'

const Home = () => {
	const currentUser = useCurrentUser()
	// const { t } = useTranslation()

	useEffect(() => {
		window.scrollTo(0, 0)
	})
	const isCoordo =
		currentUser && currentUser.groups.find(({ group }) => group === 'coordo')
	const isHq =
		currentUser && currentUser.groups.find(({ group }) => group === 'hq')
	if (!currentUser) return <Navigate to="/" />
	if (!currentUser.departments.length) return <Navigate to="/error" />
	return (
		<>
			<Header />
			<>
				{currentUser && (
					<Container maxWidth="lg">
						{isHq ? (
							<FilterByCellOrMission hQHomePage={true} />
						) : isCoordo ? (
							<FilterByProjects
								missionCode={
									currentUser &&
									currentUser.project &&
									currentUser.project.mission.code
								}
								homePage
								isCoordo
							/>
						) : (
							<>
								<FilterByProjects
									missionCode={
										currentUser &&
										currentUser.project &&
										currentUser.project.mission.code
									}
									homePage
								/>
							</>
						)}
					</Container>
				)}
			</>
		</>
	)
}

export default Home
