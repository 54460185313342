import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { StyledPaper } from './useStyles'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'

import Progress from '../Progress'
import { renderQuarterColumns } from './functions'

const ALL_DEPARTMENTS = loader(
	'../../pages/Department/graphql/allDepartments.graphql'
)

const TableThree = ({ departmentId, koboDeploymentArray }) => {
	let totalArr = []
	const { t } = useTranslation()
	const [gridColumns, setGridColumns] = useState([])

	const quarters = [
		t('average_Q1'),
		t('average_Q2'),
		t('average_Q3'),
		t('average_Q4'),
	]

	useEffect(() => {
		if (koboDeploymentArray) {
			const quarterColumns = renderQuarterColumns({
				quarters,
				totalArr,
				departmentId,
				koboDeploymentArray,
			})
			setGridColumns([
				{
					field: 'department',
					headerName: t('department'),
					valueGetter: ({ row }) => row?.name,
					flex: 1.5,
				},
				...quarterColumns,
			])
		}
	}, [departmentId, koboDeploymentArray])

	const { data: { allDepartments = null } = {}, loading: departmentsLoading } =
		useQuery(ALL_DEPARTMENTS)

	if (departmentsLoading) return <Progress />

	return (
		<>
			<h3>{t('avg_quarter_by_department')}</h3>
			<StyledPaper>
				<DataGridPro
					disableClickEventBubbling
					autoHeight
					columns={gridColumns}
					rows={
						//Adding total row
						allDepartments && departmentId === 'all departments'
							? [...allDepartments, { id: 'total', name: 'total' }]
							: [
									...allDepartments.filter(({ id }) => id === departmentId),
									{ id: 'total', name: 'total' },
							  ]
					}
					components={{ Toolbar: GridToolbar }}
				/>
			</StyledPaper>
		</>
	)
}

export default TableThree
