import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { MenuItem, InputLabel, Select } from '@mui/material'

import AssignProjectsToBatches from '../../AssignProjectsToBatches'
import Progress from '../../../UI/Progress'
import { StyledFormControl } from './useStyles'
import { sortAlphabetically } from '../../../functions'
import '../styles.css'

const CELLS = loader('./graphql/cells.graphql')
const MISSIONS = loader('./graphql/missions.graphql')

const CellAndMission = () => {
	const { t } = useTranslation()

	const [filterBy, setFilterBy] = useState(() => {
		return window.localStorage.getItem('filterBy') || 'mission'
	})

	const [selectedCode, setSelectedCode] = useState(() => {
		return window.localStorage.getItem('selectedCodeForBatch') || null
	})

	const { data: { cells = null } = {}, cellsLoading } = useQuery(CELLS)
	const { data: { missions = null } = {}, missionsLoading } = useQuery(MISSIONS)

	return (
		<>
			{cellsLoading || missionsLoading ? (
				<Progress />
			) : (
				<>
					{cells && missions && (
						<>
							<div className="formContainer">
								<StyledFormControl
									variant="outlined"
									required
									color="secondary"
								>
									<InputLabel id="filter-by">{t('filter_by')}</InputLabel>
									<Select
										labelId="filterbyInputLabel"
										name="filter"
										value={filterBy}
										onChange={({ target: { value } }) => {
											setFilterBy(value)
											window.localStorage.setItem('filterBy', value)
											setSelectedCode(null)
											window.localStorage.removeItem('selectedCodeForBatch')
										}}
									>
										{['cell', 'mission'].map((el) => (
											<MenuItem key={el} value={el}>
												{t(el)}
											</MenuItem>
										))}
									</Select>
								</StyledFormControl>
								<StyledFormControl
									variant="outlined"
									required
									color="secondary"
								>
									<InputLabel id="missionCode">
										{filterBy === 'mission' ? t('mission') : t('cell')}
									</InputLabel>
									<Select
										labelId="MissionInputLabel"
										name="missionsOrCells"
										value={selectedCode || ''}
										onChange={({ target: { value } }) => {
											setSelectedCode(value)
											window.localStorage.setItem('selectedCodeForBatch', value)
										}}
									>
										{filterBy === 'cell'
											? sortAlphabetically(cells).map(({ id, code, name }) => (
													<MenuItem key={id} value={code}>
														{name || code}
													</MenuItem>
											  ))
											: sortAlphabetically(missions).map(
													({ id, code, name }) => (
														<MenuItem key={id} value={code}>
															{code === 'all missions'
																? name
																: `${code} - ${name}`}
														</MenuItem>
													)
											  )}
									</Select>
								</StyledFormControl>
							</div>
							{selectedCode && (
								<AssignProjectsToBatches
									code={selectedCode}
									filterBy={filterBy}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	)
}

export default CellAndMission
