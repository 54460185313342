import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import {
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Button,
	Card,
	CardHeader,
	Divider,
	CircularProgress,
} from '@mui/material'

import { StyledGrid } from './useStyles'

const ASSIGN_BATCHES_TO_CHECK = loader('./graphql/assignBatchesToCheck.graphql')
const REMOVE_BATCHES_FROM_CHECK = loader(
	'./graphql/removeBatchesFromCheck.graphql'
)
const BATCHES = loader('./graphql/batches.graphql')

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
	return [...a, ...not(b, a)]
}

const CheckToBatches = ({ checkName, checkBatches }) => {
	const { t } = useTranslation()
	const { data: { batches = null } = {}, loading } = useQuery(BATCHES)
	const [assignBatchesToCheck, { loading: assignLoading }] = useMutation(
		ASSIGN_BATCHES_TO_CHECK
	)
	const [removeBatchesFromCheck, { loading: removeLoading }] = useMutation(
		REMOVE_BATCHES_FROM_CHECK
	)
	const [checked, setChecked] = useState([])
	const [left, setLeft] = useState([])
	const [right, setRight] = useState(checkBatches)

	const leftChecked = intersection(checked, left)
	const rightChecked = intersection(checked, right)

	useEffect(() => {
		if (batches && checkBatches && loading === false) {
			const checkBatchesIds = checkBatches.map(({ id }) => id)
			const batchesWithNoChecks = batches.filter(
				(el) => checkBatchesIds.indexOf(el.id) === -1
			)
			setRight(checkBatches)
			setLeft(batchesWithNoChecks)
		}
	}, [checkBatches, loading])

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const numberOfChecked = (items) => intersection(checked, items).length

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items))
		} else {
			setChecked(union(checked, items))
		}
	}

	const handleCheckedRight = () => {
		assignBatchesToCheck({
			variables: {
				input: {
					name: checkName,
					batchIds: leftChecked.map(({ id }) => id),
				},
			},
		})
		setRight(right.concat(leftChecked))
		setLeft(not(left, leftChecked))
		setChecked(not(checked, leftChecked))
	}

	const handleCheckedLeft = () => {
		removeBatchesFromCheck({
			variables: {
				input: {
					name: checkName,
					batchIds: rightChecked.map(({ id }) => id),
				},
			},
		})
		setLeft(left.concat(rightChecked))
		setRight(not(right, rightChecked))
		setChecked(not(checked, rightChecked))
	}

	const customList = (title, items) => (
		<Card>
			<CardHeader
				sx={{ px: 2, py: 1 }}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={
							numberOfChecked(items) === items?.length && items?.length !== 0
						}
						indeterminate={
							numberOfChecked(items) !== items?.length &&
							numberOfChecked(items) !== 0
						}
						disabled={items.length === 0}
						inputProps={{
							'aria-label': 'all items selected',
						}}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} selected`}
			/>
			<Divider />
			<List
				sx={{
					width: 200,
					height: 230,
					bgcolor: 'background.paper',
					overflow: 'auto',
				}}
				dense
				component="div"
				role="list"
			>
				{items.map((value) => {
					const labelId = `transfer-list-all-item-${value}-label`

					return (
						<ListItem
							key={value.id}
							role="listitem"
							button
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={value.name} />
						</ListItem>
					)
				})}
				<ListItem />
			</List>
		</Card>
	)

	return (
		<>
			<h3>{t('add_groups_to_checks')}</h3>
			<StyledGrid container spacing={2} alignItems="center">
				<Grid item>{customList('Choices', left)}</Grid>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						{loading || assignLoading || removeLoading ? (
							<CircularProgress />
						) : (
							<>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleCheckedRight}
									disabled={leftChecked.length === 0}
									aria-label="move selected right"
								>
									&gt;
								</Button>
								<Button
									sx={{ my: 0.5 }}
									variant="outlined"
									size="small"
									onClick={handleCheckedLeft}
									disabled={rightChecked.length === 0}
									aria-label="move selected left"
								>
									&lt;
								</Button>
							</>
						)}
					</Grid>
				</Grid>
				<Grid item>{customList('Chosen', right)}</Grid>
			</StyledGrid>
		</>
	)
}

export default CheckToBatches
