import { FormControl } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)(() => ({
	marginLeft: '20px',
	marginRight: '20px',
	marginBottom: '30px',
	color: '#35463D',
	borderColor: 'red',
	minWidth: 250,
	'& label': {
		backgroundColor: '#F9F9F9',
		paddingLeft: 5,
		paddingRight: 5,
	},
	'& label.Mui-focused': {
		color: '#35463D',
	},
}))
