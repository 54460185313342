import { styled } from '@mui/material/styles'
import { Button, TextField } from '@mui/material'

export const StyledButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
	marginTop: '10px',
}))

export const StyledTextField = styled(TextField)(() => ({
	width: '50%',
}))
