import calculateAvgQuarterScoreByProject from './calculateAvgQuarterScoreByProject'
import calculateAvgQuarterScore from './calculateAvgQuarterScore'

/**
 * Renders an array of columns for displaying quarterly data in a DataGrid.
 * @param {object} params - The parameters for rendering the columns.
 * @param {array} params.quarters - An array of quarter names.
 * @param {array} params.totalArr - An array to store total data for each quarter.
 * @param {string} params.departmentId - The ID of the department.
 * @param {array} params.koboDeploymentArray - An array of Kobo deployment data.
 * @param {boolean} params.project - Indicates whether the data is for a specific project.
 * @returns {array} - An array of columns for the DataGrid.
 */
const renderQuarterColumns = ({
	quarters,
	totalArr,
	departmentId,
	koboDeploymentArray,
	project,
}) => {
	return quarters.map((quarter, i) => {
		const realIndex = i + 1
		return {
			field: quarter,
			headerName: quarter,
			renderCell: ({ row }) => {
				if (row.id === 'total') {
					const findQuarter = totalArr.find((obj) => obj.quarter === realIndex)
					if (findQuarter) {
						const avg = Math.round(findQuarter.avgTotal / findQuarter.devidedBy)
						return `${avg}%`
					}
				} else {
					const avgTotal = project
						? calculateAvgQuarterScoreByProject({
								quarter: realIndex,
								selectedDepartmentId:
									departmentId === 'all departments'
										? null
										: Number(departmentId),
								projectCode: row.code,
								koboDeploymentArray,
						  })
						: calculateAvgQuarterScore({
								quarter: realIndex,
								selectedDepartmentId: Number(row.id),
								koboDeploymentArray,
						  })

					if (typeof avgTotal === 'number' && avgTotal > -1) {
						const found = totalArr.find(({ quarter }) => quarter === realIndex)
						if (found) {
							const newObj = {
								quarter: realIndex,
								avgTotal: avgTotal + found.avgTotal,
								devidedBy: found.devidedBy + 1,
							}
							totalArr = totalArr.filter(
								({ quarter }) => quarter !== found.quarter
							)
							totalArr = [...totalArr, newObj]
						} else {
							totalArr.push({
								quarter: realIndex,
								avgTotal,
								devidedBy: 1,
							})
						}
					}

					return typeof avgTotal === 'number' && avgTotal > -1
						? `${avgTotal}%`
						: '%'
				}
			},
			flex: 1,
		}
	})
}

export default renderQuarterColumns
