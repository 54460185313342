import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import './index.css'
import App from './app/App'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from '@apollo/client'
import reportWebVitals from './reportWebVitals'

// const { hostname, port } = window.location

LicenseInfo.setLicenseKey(
	'3fbcfdd553e94139031f6eaf8777260aTz04MDIwNSxFPTE3MzM2NjAzMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

const uploadLink = createUploadLink({
	credentials: 'include', // Send the cookie along with every request
	uri: '/graphql',
})

// const httpLink = new ApolloLink.from([
// 	new HttpLink({
// 		// credentials: 'include',
// 		uri: '/graphql',
// 	}),
// ])

const client = new ApolloClient({
	cache: new InMemoryCache(),
	defaultOptions: {
		mutate: { errorPolicy: 'all' },
		query: { errorPolicy: 'all', partialRefetch: true },
		watchQuery: { errorPolicy: 'all' },
	},
	link: uploadLink,
	// resolvers,
	shouldBatch: true,
	// typeDefs
})

// ReactPDF.render(<PrintOrder />, `${__dirname}/example.pdf`)

ReactDOM.render(
	<ApolloProvider client={client}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ApolloProvider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
