export const TRANSLATIONS_ES = {
	select_file: 'Seleccione un archivo',
	welcome_msg: 'Bienvenido a la herramienta de control interno 3.0',
	welcome_home: 'Bienvenido a la página de WeCheck',
	joined_groups: 'Grupos integrados',
	department: 'Departamento',
	project: 'Proyecto',
	name: 'Nombre',
	file_upload: 'Subir archivos',
	upload_file_to: 'Cargar el archivo en',
	create_check: 'Crear un control',
	select_xlsx_file_and_drag: 'Seleccione el archivo .xlsx o arrástrelo aquí',
	check_date_period: 'Fecha y período de envío',
	period: 'Período',
	month: 'Mês',
	set_send_date_and_period: 'Establecer la fecha de envío y el período ',
	save: 'Salvar',
	update: 'Actualizar',
	set_period: 'Establecer período de envío',
	set_date: 'Establecer la fecha de envío',
	future_date_error: 'Por favor, seleccione una fecha futura',
	cell: 'Célula',
	mission: 'Misión',
	filter_by: 'Filtrar por',
	checks: 'Controles',
	submitted_checks: 'Controles enviados',
	pending_checks: 'Controles pendientes',
	overdue_checks: 'Controles atrasados',
	of_targets_hit: 'Objetivos a alcanzar',
	the_overall_performance: 'El desempeño general',
	keep_track: 'Controlar los resultados globales',
	you_have_submitted: 'Usted ha enviado',
	hello: 'Hola',
	view_answers: 'Ver respuestas',
	you_have_answered: 'Usted ha respondido',
	questions: 'Preguntas',
	receive_date: 'Fecha de recepción',
	send_date: 'Fecha de envío',
	status: 'Status',
	view: 'Visualizar',
	submit_date: 'Fecha de envío',
	project_code: 'Código de proyecto',
	check: 'Control',
	check_form: 'Formulario de control',
	archived: 'Archivado',
	all: 'Todo',
	sent: 'Enviado',
	received: 'Recibido',
	overdue: 'Atrasado',
	link: 'Link',
	click_here: 'Haga clic aquí',
	to_review_your_answers: 'Revisar tus respuestas',
	recently_have_submitted: 'Recientemente has enviado',
	one: 'Un',
	pending: 'Pendiente',
	completed: 'Completado',
	to_complete_submitting_checks:
		'Para completar el envío de sus controles mensuales',
	you_have: 'Tienes',
	is: 'Es',
	are: 'Son',
	corrective_measures: 'Medidas correctivas',
	the_score_is: 'La puntuación es',
	priority: 'Prioridad',
	corrective_measures_thanks: 'Gracias por enviar las medidas correctivas',
	corrective_measures_form: 'Formulario de medidas correctivas',
	form_error_msg: 'Asegúrese de rellenar todos los campos',
	submitted_corrective_measures: 'Medidas correctivas presentadas',
	score: 'Puntuación',
	submit_corrective_measures: 'Enviar las medidas correctivas',
	corrective_measures_required: 'Medidas correctivas requeridas',
	the_function_in_charge: 'La función encargada',
	due_date: 'Fecha de vencimiento',
	low: 'Bajo',
	received_checks: 'Controles recibidos',
	medium: 'Medio',
	high: 'alto',
	all_missions: 'Todas las misiones',
	all_cells: 'Todas las células',
	all_projects: 'Todos los proyectos',
	all_departments: 'Todos los departamentos',
	from: 'De',
	to: 'Hasta',
	corrective_measures_score: 'Puntuación de las medidas correctivas',
	graph: 'Gráfico',
	year: 'Año',
	num_of_received_checks: '# de controles recibidos',
	num_of_pending_checks: '# de controles pendientes',
	num_of_sent_checks: '# de controles enviados',
	num_of_overdue_checks: '# de controles vencidos',
	january: 'Enero',
	february: 'Febrero',
	march: 'Marzo',
	april: 'Abril',
	may: 'Mayo',
	june: 'Junio',
	july: 'Julio',
	august: 'Agosto',
	september: 'Septiembre',
	october: 'Octubre',
	november: 'Noviembre',
	december: 'Diciembre',
	sent_checks: 'Controles enviados',
	submit: 'Enviar',
	home: 'Inicio',
	manage_projects: 'Gestión de proyectos',
	upload: 'Cargar',
	sign_out: 'Desconectar',
	sign_in: 'Iniciar sesión',
	group_name: 'Nombre del grupo',
	create_group: 'Crear un grupo',
	assign_projects_to_groups: 'Asignar proyectos a grupos',
	add_groups_to_checks: 'Añadir grupos a los controles',
	back_home: 'Volver a Inicio',
	email: 'Correo electrónico',
	out_of_office: 'Ausencia en el cargo',
	in_office_date: 'Presente en la oficina ',
	manage_out_of_office: 'Gestionar ausencia',
	manage_users: 'Gestionar usuarios',
	canceled: 'Cancelado',
	impact: 'Impacto',
	person_in_charge: 'Persona encargada',
	in_charge: 'Encargado(a)',
	corrective_measures_link: 'Link a las medidas correctivas',
	sample: 'Muestra',
	monthly_pending_checks: 'Controles mensuales pendientes',
	project_summary: 'Resumen del proyecto',
	mission_summary: 'Resumen de la misión',
	summary_comment_text: 'Añada un comentario aquí para resumirlo',
	summary_comment: 'Comentario resumido',
	about_1:
		'La Herramienta de Controles Internos (WeCheck) es un ejercicio de autoevaluación basado en un conjunto de controles predefinidos que deben ser realizados regularmente en todos los proyectos y en todas las misiones por todos los supervisores de los departamentos (Supply, Logística, Finanzas, Recursos Humanos, Médico y Operaciones).',
	about_2:
		' A través de este ejercicio, los supervisores de los departamentos controlan si los procedimientos o procesos básicos validados por MSF OCB se aplican correcta y sistemáticamente en sus departamentos. Se trata, por tanto, de una herramienta importante para la gestión del proyecto por parte de los supervisores de los departamentos y del coordinador del proyecto."',
	label: 'Etiqueta',
	category: 'Categoría',
	last_send_date: 'Última fecha de envío',
	timing: 'Hora',
	project_comment: 'Comentario del proyecto',
	mission_comment: 'Comentario de la misión',
	period_checks: 'Controles del período',
	monthly_completed_checks: 'Controles mensuales realizados',
	support: 'Soporte',
	average_Q1: 'Promedio Q1',
	average_Q2: 'Promedio Q2',
	average_Q3: 'Promedio Q3',
	average_Q4: 'Promedio Q4',
	clear_filter: 'Borrar el filtro',
	department_not_present: 'Departamento ausente',
	results_by_department_month: 'Resultados por departamento y mês',
	results_by_project_month: 'Resultados por proyecto y mês',
	avg_quarter_by_department: 'Resultados medios trimestrales por departamento',
	avg_quarter_by_project: 'Resultados medios trimestrales por proyecto',
	evolution_avg_quarterly_by_department:
		'Evolución de los resultados medios trimestrales por departamento',
	evolution_avg_quarterly_by_project:
		'Evolución de los resultados medios trimestrales por proyecto',
	list_of_cm: 'Lista de medidas correctivas',
	checks_are_out: 'Los controles ya están disponibles',
	here: 'Aquí',
	checks_home_page_msg:
		'No olvide completar su control mensual antes de que termine el mes haciendo clic em',
	checks_are_still_pending: 'Los controles siguen pendientes',
	great_job: 'Buen trabajo',
	checks_have_been_submitted: 'Se han enviado los controles',
	present: 'Presente',
	not_present: 'Ausente',
	departments_checks_results_by_month:
		'Resultados de los controles del departamento por mês',
	submitted: 'Enviado',
	comment: 'Comentario',
	checks_of_month: 'Los controles de',
	have_been_completed: 'Han sido completados',
	error: 'Error',
	access_error:
		'Está intentando acceder a WeCheck usando este correo electrónico',
	screenshot_msg: 'Tome una captura de pantalla y envíela a',
	change_request:
		'Si desea solicitar un cambio / eliminación / adición de uno de los cheques, comuníquese con',
	following_info: 'con la siguiente información',
	your_mission: 'Su misión',
	your_project: 'Su proyecto',
	your_department: 'Su departamento',
	change_question: 'Pregunta que le gustaría cambiar',
	contextual_reason: 'razón contextual',
	techincal_contact: 'Si tiene problemas técnicos, póngase en contacto con',
	view_questions: 'Ver preguntas',
	modify: 'modificar',
	success_request_msg: '¡Su solicitud ha sido enviada con éxito!',
	non_inOfficeDate_msg:
		'Nota: Si no desea establecer una fecha de regreso a la oficina, haga clic en borrar después de establecer "desde la fecha" como se muestra en la captura de pantalla a continuación, luego haga clic en guardar.',
	mark_ooo_msg: `Este proyecto mostrará que todo su departamento no está disponible actualmente, por lo que no se enviarán cheques a su departamento hasta que esté disponible nuevamente.`,
	send: 'Enviar',
	no_user_found:
		'No hay ningún usuario asignado a este proyecto o este departamento',
	send_now: 'Enviar ahora',
	user: 'Usuario',
	send_check_error:
		'Hubo un error durante el envío del control, inténtelo nuevamente más tarde o comuníquese con el administrador.',
	send_check_success_msg: 'El control ha sido enviado exitosamente.',
	wait: '¡Espere por favor!',
	powerbi_report: 'Informe PowerBI',
	no_user_assigned_department_project: 'No hay ningún usuario asignado a este departamento en este proyecto',
}
