import React from 'react'
import CheckboxArray from '../CheckboxArray'
import './styles.css'

const ProjectsList = ({ projects }) => {
	return (
		<>
			<div>
				{projects &&
					projects.map(({ id, code, name, batches }) => {
						return (
							<div key={id} className="batchProjectContainer">
								<div className="projectNameContainer">
									<span>
										{name} - {code}
									</span>
								</div>
								<CheckboxArray selectedBatches={batches} projectCode={code} />
							</div>
						)
					})}
			</div>
		</>
	)
}

export default ProjectsList
