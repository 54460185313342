//This function to handle the calculating of the overall score per month
//Note: use UTC time like the server, not local time from the client, to avoid time zone issues (e.g. 01-Jan AM on server may still be 31-Dec PM on client!)

const handleCalculatingOverAllScoreForKoboDeploymentArrPerMonth = ({
	month,
	koboDeploymentArray,
	departmentId,
	projectCode,
}) => {
	const filterCancelledAndDepartment = departmentId
		? koboDeploymentArray.filter(
				({ status, check, score, project, createdAt }) =>
					status !== 'canceled' &&
					check.category.department.id === departmentId &&
					new Date(createdAt).getUTCMonth() === month &&
					score > -1 &&
					typeof score === 'number' &&
					project.code === projectCode
		  )
		: koboDeploymentArray.filter(
				({ status, score, project, createdAt }) =>
					status !== 'canceled' &&
					score > -1 &&
					typeof score === 'number' &&
					project.code === projectCode &&
					new Date(createdAt).getUTCMonth() === month
		  )

	if (filterCancelledAndDepartment.length) {
		const scoreArr = filterCancelledAndDepartment.map(({ score }) => score)

		if (scoreArr.length) {
			return parseInt(
				scoreArr.reduce((a, b) => a + b) / filterCancelledAndDepartment.length
			)
		}
	}
}

export default handleCalculatingOverAllScoreForKoboDeploymentArrPerMonth
