import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useCurrentUser } from '../../CurrentUserContext'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import CheckTable from '../CheckTable'
import NewsFeed from '../NewsFeed'
import Graph from '../Graph'

// const KOBO_DEPLOYMENTS_BY_PROJECT_AND_ALL_DEPARTMENTS = loader(
// 	'../CheckTable/graphql/koboDeploymentsByProjectAndAllDepartments.graphql'
// )

const BreakDownBox = ({
	checkTable,
	departmentId,
	projectCode,
	filterBy,
	selectedCode,
	selectedStatus,
	fromDate,
	toDate,
	year,
}) => {
	const [results, setResults] = useState()
	const [newsFeed, setNewsFeed] = useState(false)
	const currentUser = useCurrentUser()
	const [koboDeploymentArray, setKoboDeploymentArray] = useState([])

	useEffect(() => {
		// setKoboDeploymentArray(null)
		if (
			filterBy === 'cell' &&
			selectedCode !== 'all cells' &&
			selectedCode &&
			departmentId === 'all departments' &&
			projectCode === 'all projects'
		) {
			axios
				.get('/checks-by-cell', {
					params: {
						cell_code: selectedCode,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'cell' &&
			selectedCode !== 'all cells' &&
			selectedCode &&
			departmentId !== 'all departments' &&
			projectCode === 'all projects'
		) {
			//koboDeploymentsByCellAndDepartment
			axios
				.get('/checks-by-cell-department', {
					params: {
						cell_code: selectedCode,
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'mission' &&
			selectedCode !== 'all missions' &&
			selectedCode &&
			projectCode === 'all projects' &&
			departmentId === 'all departments'
		) {
			//getKoboDeploymentsByMission
			axios
				.get('/checks-by-mission', {
					params: {
						mission_code: selectedCode,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'mission' &&
			selectedCode !== 'all missions' &&
			departmentId !== 'all departments' &&
			projectCode === 'all projects'
		) {
			//koboDeploymentsByMissionAndDepartment
			axios
				.get('/checks-by-mission-department', {
					params: {
						mission_code: selectedCode,
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'cell' &&
			selectedCode === 'all cells' &&
			departmentId === 'all departments'
		) {
			axios
				.get('/checks-by-all-cells', {
					params: {
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'mission' &&
			selectedCode === 'all missions' &&
			departmentId === 'all departments'
		) {
			//Filtering by all cells and all missions are the same
			axios
				.get('/checks-by-all-cells', {
					params: {
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'cell' &&
			selectedCode === 'all cells' &&
			departmentId !== 'all departments'
		) {
			//Filtering by all cells and a department
			axios
				.get('/checks-by-all-cells-department', {
					params: {
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			filterBy === 'mission' &&
			selectedCode === 'all missions' &&
			departmentId !== 'all departments'
		) {
			//Filtering by all mission and a department same endpoint as allCellsAndDepartment
			axios
				.get('/checks-by-all-cells-department', {
					params: {
						departmentId,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		} else if (
			projectCode &&
			projectCode !== 'all projects' &&
			departmentId === 'all departments'
		) {
			//Filtering by project and all departments
			axios
				.get('/checks-by-project-all-departments', {
					params: {
						projectCode,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		}

		if (
			currentUser.departments.length &&
			projectCode &&
			projectCode !== 'all projects' &&
			departmentId !== 'all departments'
		) {
			axios
				.get('/checks-by-project-department', {
					params: {
						projectCode,
						departmentId: departmentId || currentUser.department.id,
						year,
					},
				})
				.then((response) => {
					return setResults(response?.data)
				})
		}
	}, [year, projectCode, departmentId, filterBy, selectedCode])

	useEffect(() => {
		// setKoboDeploymentArray(null)
		if (
			results &&
			projectCode !== 'all projects' &&
			projectCode &&
			departmentId !== 'all departments'
		) {
			//Filter by project and department
			//Show news feed
			setNewsFeed(true)
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === 'cell' &&
			selectedCode !== 'all cells' &&
			selectedCode &&
			departmentId !== 'all departments' &&
			projectCode === 'all projects'
		) {
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === 'mission' &&
			selectedCode !== 'all missions' &&
			departmentId !== 'all departments' &&
			projectCode === 'all projects'
		) {
			//Filter by a mission and department
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === 'cell' &&
			selectedCode !== 'all cells' &&
			projectCode === 'all projects' &&
			departmentId === 'all departments'
		) {
			//Filter by a cell and all departments
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === 'mission' &&
			selectedCode !== 'all missions' &&
			projectCode === 'all projects' &&
			departmentId === 'all departments'
		) {
			//Filter by a mission and all departments
			setKoboDeploymentArray(results)
		}

		if (
			//koboDeploymentByAllCells &&
			results &&
			filterBy === 'cell' &&
			selectedCode === 'all cells' &&
			departmentId === 'all departments'
		) {
			//Filter by all cells
			setKoboDeploymentArray(results)
		}

		if (
			results &&
			filterBy === 'mission' &&
			selectedCode === 'all missions' &&
			departmentId === 'all departments'
		) {
			//Filter by all missions
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === 'cell' &&
			selectedCode === 'all cells' &&
			departmentId !== 'all departments'
		) {
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			filterBy === 'mission' &&
			selectedCode === 'all missions' &&
			departmentId !== 'all departments'
		) {
			setKoboDeploymentArray(results)
		}
		if (
			results &&
			projectCode &&
			projectCode !== 'all projects' &&
			departmentId === 'all departments'
		) {
			//Filtering by project and all departments
			setKoboDeploymentArray(results)
		}
	}, [departmentId, projectCode, year, results])
	return (
		<>
			{currentUser &&
				currentUser.project &&
				checkTable === false &&
				newsFeed && (
					<>
						<NewsFeed checks={results || []} />
					</>
				)}
			{koboDeploymentArray.length ? (
				<Graph
					koboDeploymentArray={koboDeploymentArray}
					departmentId={departmentId}
					projectCode={projectCode}
					cellCode={filterBy === 'cell' ? selectedCode : null}
					missionCode={filterBy === 'mission' ? selectedCode : null}
					year={year}
				/>
			) : (
				koboDeploymentArray &&
				checkTable && (
					<CheckTable
						koboDeploymentArray={koboDeploymentArray}
						selectedStatus={selectedStatus}
						fromDate={fromDate}
						toDate={toDate}
					/>
				)
			)}
		</>
	)
}

export default BreakDownBox
