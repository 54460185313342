import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// import translations file
import { TRANSLATIONS_EN } from '../translations/en/translations'
import { TRANSLATIONS_FR } from '../translations/fr/translations'
import { TRANSLATIONS_ES } from '../translations/es/translations'
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		lng: window.localStorage.i18nextLng || 'en',
		resources: {
			fr: {
				// use translations file for French resources
				translation: TRANSLATIONS_FR,
			},
			en: {
				// use translations file for English resources
				translation: TRANSLATIONS_EN,
			},
			es: {
				// use translations file for English resources
				translation: TRANSLATIONS_ES,
			},
		},
		supportedLngs: ['en', 'fr', 'es'],
	})
