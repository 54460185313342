import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import OooTable from '../../UI/OooTable'
import Header from '../../UI/Header'
import { Container } from '@mui/material'
import useStyles from './useStyles'
import './style.css'

const Profile = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const {
		cellContainer,
		container,
		groupsContainer,
		groupList,
		mainContainer,
	} = useStyles()

	if (!currentUser) return <Navigate to="/" />
	return (
		<>
			<Header />
			<Container className={container}>
				<div style={{ borderBottom: '1px solid #eae8e8' }}>
					<h2>{currentUser.userName}</h2>
					<h3>{currentUser.email}</h3>
				</div>

				<div className={mainContainer}>
					{currentUser.groups.length ? (
						<div className={groupsContainer}>
							<h4>{t('joined_groups')}</h4>
							{currentUser.groups.length === 1 ? (
								<p>{currentUser.groups[0].group}</p>
							) : (
								<ul className={groupList}>
									{currentUser.groups.map(({ group, id }) => (
										<li key={id}>{group}</li>
									))}
								</ul>
							)}
						</div>
					) : null}

					{currentUser.departments ? (
						<div>
							<h4>{t('department')}</h4>
							{currentUser.departments.map(({ id, name }) => (
								<p key={id}>{name}</p>
							))}
						</div>
					) : null}
				</div>

				{currentUser.project ? (
					<>
						<div className={cellContainer}>
							<div className="aligned-left-div">
								<h4>Cell</h4>
								<p>
									<span>code:</span> {currentUser.project.cell.code}
								</p>
							</div>
							<div className="aligned-left-div">
								<h4>Mission</h4>
								<p>
									<span>code:</span> {currentUser.project.mission.code}
								</p>
								<p>
									<span>name:</span> {currentUser.project.mission.name}
								</p>
							</div>
							<div className="aligned-left-div">
								<h4>{t('project')}</h4>
								<p>
									<span>code:</span> {currentUser.project.code}
								</p>
								<p>
									<span>{t('name')}:</span> {currentUser.project.name}
								</p>
							</div>
						</div>
					</>
				) : null}
				<div style={{ borderBottom: '1px solid #eae8e8', margin: '20px 20px' }}>
					{' '}
				</div>

				{currentUser.project
					? currentUser.departments.map(({ id }) => (
							<OooTable
								userId={currentUser.id}
								departmentId={id}
								showEmail={false}
							/>
					  ))
					: null}
			</Container>
		</>
	)
}

export default Profile
