import { Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledPaper = styled(Paper)(() => ({
	width: '90%',
	margin: 'auto',
}))

export const StyledPaperPending = styled(Paper)(() => ({
	width: '50%',
	margin: 'auto',
	marginBottom: '35px',
}))
