import { AppBar, Button, Toolbar } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'

import { styled } from '@mui/material/styles'

export const StyledAppBar = styled(AppBar)(() => ({
	boxShadow: 'none',
	borderBottom: '1px solid #ddd',
	backgroundColor: '#FEFEFE',
	paddingRight: '0',
	paddingLeft: '0',
	'@media (max-width: 900px)': {
		paddingLeft: 0,
	},
}))

export const StyledToolbar = styled(Toolbar)(() => ({
	display: 'flex',
	paddingRight: '20px',
	paddingLeft: '20px',
}))

export const StyledMenuButton = styled(Button)(() => ({
	color: '#353535',
	fontSize: '.8em',
	fontWeight: 300,
	fontFamily:
		'Helvetica Neue,Helvetica,Helv,Roboto,Arial,Lucida Grande,sans-serif',
	marginLeft: '12px',
}))

export const StyledLoginButton = styled(Button)(() => ({
	backgroundColor: '#FD0000',
	textTransform: 'none',
}))

export const StyledLoginAccountCircle = styled(AccountCircle)(() => ({
	color: 'black',
	fontSize: '30px',
}))

// const useStyles = styled(() => ({
// 	accountCircleIcon: {
// 		color: 'black',
// 		fontSize: '30px',
// 	},

// 	header: {
// 		boxShadow: 'none',
// 		borderBottom: '1px solid #ddd',
// 		backgroundColor: '#FEFEFE',
// 		paddingRight: '0',
// 		paddingLeft: '0',
// 		'@media (max-width: 900px)': {
// 			paddingLeft: 0,
// 		},
// 	},
// 	logo: {
// 		width: '60px',
// 		textAlign: 'left',
// 	},
// 	logoText: {
// 		color: '#4c4a4a',
// 		marginLeft: '10px',
// 		fontWeight: 'bold',
// 	},
// 	menuButton: {
// 		color: '#353535',
// 		fontSize: '.8em',
// 		fontWeight: 300,
// 		fontFamily:
// 			'Helvetica Neue,Helvetica,Helv,Roboto,Arial,Lucida Grande,sans-serif',
// 		marginLeft: '12px',
// 	},
// 	toolbar: {
// 		display: 'flex',
// 		paddingRight: '20px',
// 		paddingLeft: '20px',
// 	},
// 	drawerContainer: {
// 		padding: '20px 30px',
// 	},

// 	loginButton: {
// 		backgroundColor: '#FD0000',
// 		textTransform: 'none',
// 	},
// }))

// export default useStyles
