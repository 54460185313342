/**
 * CorrectiveMeasures Component
 *
 * The CorrectiveMeasures component allows users to provide and update corrective measures for a specific task.
 * It provides a form for entering the due date, priority, status, required measures, and the function in charge of the task.
 *
 * @returns {JSX} A component that displays and updates corrective measures for a task.
 */

import { equals, isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Navigate, useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../CurrentUserContext'
import { Select, MenuItem, InputLabel } from '@mui/material'
import SubmittedView from './SubmittedView'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import { getISODayStrFromDate } from '../../functions'
import {
	StyledButton,
	StyledTextField,
	StyledTextFieldInput,
	StyledFormControl,
} from './useStyles'
import './styles.css'

// GraphQL queries and mutations
const CORRECTIVE_MEASURES = loader(
	'./graphql/correctiveMeasuresByKoboDeployment.graphql'
)
const UPDATE_CORRECTIVE_MEASURES = loader(
	'./graphql/updateCorrectiveMeasures.graphql'
)

// Constants for the "Priority" dropdown
const priorityArray = Object.freeze(['low', 'medium', 'high'])
// Constants for the "Status" dropdown
const statusArray = Object.freeze(['pending', 'completed', 'overdue'])

// Call back function used to help prevent the user from leaving the page without saving
const unloadCallback = (event) => {
	event.preventDefault()
	event.returnValue = ''
	return ''
}

// React component for the "Corrective Measures Form" page
const CorrectiveMeasures = () => {
	const { koboDeploymentUid } = useParams()
	const today = new Date()
	const currentUser = useCurrentUser()
	const language = window.localStorage.i18nextLng
	const { t } = useTranslation()

	// State variables
	// - pastDateError: true if the selected due date is not tomorrow or later in the future
	const [pastDateError, setPastDateError] = useState(false)
	// - values: the values of the form fields
	const [values, setValues] = useState({})
	// - loadedValues: the values of the form fields initially loaded from the GraphQL query
	const [loadedValues, setLoadedValues] = useState({})
	// - valuesCanBeSaved: true if all form values are valid and any has been changed
	const [valuesCanBeSaved, setValuesCanBeSaved] = useState(false)

	// GraphQL queries and mutations
	// - Get the corrective measures for the task with the given Kobo deployment UID
	const { data: { correctiveMeasuresByKoboDeployment = null } = {}, loading } =
		useQuery(CORRECTIVE_MEASURES, {
			variables: {
				koboDeploymentUid,
			},
		})
	// - Update the corrective measures for the task with the given Kobo deployment UID
	const [updateCorrectiveMeasures, { data = null, loading: updateLoading }] =
		useMutation(UPDATE_CORRECTIVE_MEASURES)

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault()
		const ifValueIsNull = Object.values(values).filter((value) => !value)
		// As defensive action, check again all form values, and if any is null display an error message and abort the submission
		if (ifValueIsNull.length) {
			return alert(t('form_error_msg'))
		}
		// If the form values are valid, update the edited corrective measure
		return updateCorrectiveMeasures({
			variables: {
				input: {
					...values,
					id: correctiveMeasuresByKoboDeployment.id,
				},
			},
		})
	}

	// Load the values for the form via the GraphQL query and save a copy of these initial values to easily detect form field changes
	useEffect(() => {
		if (correctiveMeasuresByKoboDeployment) {
			const defaultValues = {
				dueDate: getISODayStrFromDate(
					correctiveMeasuresByKoboDeployment.dueDate
						? new Date(Number(correctiveMeasuresByKoboDeployment.dueDate))
						: new Date()
				),
				priority:
					correctiveMeasuresByKoboDeployment.priority || priorityArray[0],
				status: correctiveMeasuresByKoboDeployment.status || statusArray[0],
				requiredMeasures:
					correctiveMeasuresByKoboDeployment.requiredMeasures || '',
				functionInCharge:
					correctiveMeasuresByKoboDeployment.functionInCharge || '',
			}
			setLoadedValues(defaultValues)
			setValues(defaultValues)
		}
	}, [correctiveMeasuresByKoboDeployment])

	// Check if the form values have changed and if so, check if they are valid
	useEffect(() => {
		if (values && !isEmpty(values)) {
			if (equals(values, loadedValues)) {
				setValuesCanBeSaved(false)
				return
			}
			// Check if the selected due date is tomorrow or later in the future
			let validDueDate = false
			if (values?.dueDate) {
				const chosenDate = new Date(values?.dueDate)
				if (chosenDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
					validDueDate = true
				}
				setPastDateError(!validDueDate)
			}
			const areValuesValid =
				validDueDate &&
				!!values?.priority &&
				!!values?.status &&
				!!values?.requiredMeasures?.trim().length &&
				!!values?.functionInCharge?.trim().length
			setValuesCanBeSaved(areValuesValid)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]) // Note: only include `values` from the dependency array, to exclude unsuitable events

	// If any value of the form was changed, ask for confirmation if leaving the page without saving
	// see e.g. <https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd>
	// Note: this may not yet work for all cases (e.g. if clicking a link in the header)
	useEffect(() => {
		if (valuesCanBeSaved) {
			window.addEventListener('beforeunload', unloadCallback)
		} else {
			window.removeEventListener('beforeunload', unloadCallback)
		}
	}, [valuesCanBeSaved])

	// If the user is not logged in, redirect to the login page
	if (!currentUser) return <Navigate to="/" />

	return (
		<>
			{/* Display the header links*/}
			<Header />
			{/* Display a progress indicator while loading the data*/}
			{loading || updateLoading ? (
				<Progress />
			) : (
				<>
					{new Date(
						Number(correctiveMeasuresByKoboDeployment.koboDeployment.dueDate)
					) < today ? (
						// If the due date is in the past, display the submitted view
						<SubmittedView
							correctiveMeasures={correctiveMeasuresByKoboDeployment}
						/>
					) : (
						// Otherwise, display the form
						correctiveMeasuresByKoboDeployment && (
							<>
								{/* Display the title of the page (translated) */}
								<h1>{t('corrective_measures_form')}</h1>
								{/* Display the title of the task (translated) */}
								<h3>
									{language === 'en'
										? correctiveMeasuresByKoboDeployment.koboDeployment.check
												.label
										: correctiveMeasuresByKoboDeployment.koboDeployment.check
												.labelFr}
								</h3>
								{/* Display the score of the task */}
								<h3 style={{ marginBottom: '40px' }}>
									{t('the_score_is')} {correctiveMeasuresByKoboDeployment.score}
									%
								</h3>

								{/* Main form container */}
								<div>
									<form
										className="form"
										noValidate
										autoComplete="off"
										onSubmit={handleSubmit}
									>
										<div className="dateContainer">
											{/* Text field to select the due date (calendar dropdown, text not editable) */}
											<StyledTextFieldInput
												id="outlined-basic"
												label={`${t('due_date')} - dd/mm/yyyy`}
												variant="outlined"
												required
												name="dueDate"
												type="date"
												color="secondary"
												format="YYYY-MM-DD"
												InputLabelProps={{
													shrink: true,
												}}
												value={
													values?.dueDate || getISODayStrFromDate(new Date())
												}
												onChange={({ target: { value } }) => {
													setValues({ ...values, dueDate: value })
												}}
											/>
											{/* Error message if the selected due date is not future */}
											{pastDateError && (
												<p className="errorMsg">{t('future_date_error')}</p>
											)}
										</div>
										<div className="dateContainer">
											{/* Dropdown to select priority */}
											<StyledFormControl
												variant="outlined"
												required
												color="secondary"
											>
												<InputLabel id="priority">{t('priority')}</InputLabel>
												<Select
													labelId="InputLabel"
													name="priority"
													value={values?.priority || priorityArray[0]}
													onChange={({ target: { value } }) => {
														setValues({ ...values, priority: value })
													}}
												>
													{priorityArray.map((element, i) => (
														<MenuItem key={i} value={element}>
															{t(element)}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
											{/* Dropdown to select status */}
											<StyledFormControl
												variant="outlined"
												required
												color="secondary"
											>
												<InputLabel id="status">{t('status')}</InputLabel>
												<Select
													labelId="InputLabel"
													name="status"
													value={values?.status || statusArray[0]}
													onChange={({ target: { value } }) => {
														setValues({ ...values, status: value })
													}}
												>
													{statusArray.map((element, i) => (
														<MenuItem key={i} value={element}>
															{t(element)}
														</MenuItem>
													))}
												</Select>
											</StyledFormControl>
										</div>

										{/* Text field to enter corrective measures required */}
										<StyledTextField
											label={t('corrective_measures_required')}
											variant="outlined"
											type="text"
											name="requiredMeasures"
											required
											multiline={true}
											color="secondary"
											value={values?.requiredMeasures || ''}
											onChange={({ target: { value } }) => {
												setValues({
													...values,
													requiredMeasures: value,
												})
											}}
											// Trim the text input when the field loses focus
											onBlur={({ target: { value } }) => {
												const trimmedValue = value?.trim()
												if (trimmedValue !== value) {
													setValues({
														...values,
														requiredMeasures: trimmedValue,
													})
												}
											}}
											error={values?.requiredMeasures?.trim().length < 1}
										/>

										{/* Text field to enter the function in charge */}
										<StyledTextField
											label={t('the_function_in_charge')}
											variant="outlined"
											type="text"
											name="functionInCharge"
											required
											multiline={true}
											color="secondary"
											value={values?.functionInCharge || ''}
											onChange={({ target: { value } }) => {
												setValues({
													...values,
													functionInCharge: value,
												})
											}}
											// Trim the text input when the field loses focus
											onBlur={({ target: { value } }) => {
												const trimmedValue = value?.trim()
												if (trimmedValue !== value) {
													setValues({
														...values,
														functionInCharge: trimmedValue,
													})
												}
											}}
											error={values?.functionInCharge?.trim().length < 1}
										/>

										{/* Button to submit the form, only enabled if any form field changed and all values are valid */}
										<StyledButton
											type="submit"
											variant="contained"
											color="secondary"
											disabled={!valuesCanBeSaved}
										>
											{t('save')}
										</StyledButton>

										{/* If the form was submitted successfully, display a success message and a link to the home page */}
										{data &&
										data.updateCorrectiveMeasures &&
										!valuesCanBeSaved ? (
											<>
												<p>{t('success_request_msg')}</p>
												<p>
													<Link
														to="/home"
														color="inherit"
														style={{
															textDecoration: 'none',
															fontWeight: 'bold',
														}}
													>
														{t('home')}
													</Link>
												</p>
											</>
										) : (
											''
										)}
									</form>
								</div>
							</>
						)
					)}
				</>
			)}
		</>
	)
}

export default CorrectiveMeasures
