import { FormControl } from '@mui/material'
import { Edit, Send } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

export const StyledFormControl = styled(FormControl)(() => ({
	marginLeft: '50px',
	marginBottom: '50px',
	color: '#35463D',
	borderColor: 'red',
	minWidth: 250,
	'& label': {
		backgroundColor: '#F9F9F9',
		paddingLeft: 5,
		paddingRight: 5,
	},
	'& label.Mui-focused': {
		color: '#35463D',
	},
}))

export const StyledEditIcon = styled(Edit)(() => ({
	cursor: 'pointer',
}))

export const StyledSendIcon = styled(Send)(() => ({
	cursor: 'pointer',
}))
