import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { useCurrentUser } from '../../CurrentUserContext'
import { useMutation, useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import XLSX from 'xlsx'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import { StyledButton } from './useStyles'
import '../../../translations/i18n'
import './styles.css'

const CHECK = loader('../Questions/graphql/check.graphql')
const CREATE_UPLOAD = loader('./graphql/createUpload.graphql')
const SINGLE_UPLOAD = loader('./graphql/singleUpload.graphql')

const Upload = () => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const [result, setResult] = useState(null)
	const [xlsxRows, setXlsxRows] = useState(null)
	const [choiceListSheetRows, setChoiceListSheetRows] = useState(null)
	const [openDialog, setOpenDialog] = useState(false)
	const [uploadVariables, setUploadVariables] = useState(null)
	const [uploadedFile, setUploadedFile] = useState({
		singleFile: null,
		files: null,
	})
	const [xlsxError, setXlsxError] = useState(null)

	const [
		checkData,
		{ data: { check = null } = {}, loading: checkLoading, refetch },
	] = useLazyQuery(CHECK)

	const [
		createUpload,
		{
			data: { createUpload: upload = null } = {},
			error: uploadError,
			loading: uploadLoading,
		},
	] = useMutation(CREATE_UPLOAD)
	const [singleUpload, { data = {}, error, loading }] =
		useMutation(SINGLE_UPLOAD)
	const processExcel = (xlsxData, fileData) => {
		const workbook = XLSX.read(xlsxData, { type: 'binary' })
		const firstSheet = workbook.SheetNames[0]
		const secondSheet = workbook.SheetNames[1]
		const excelRows = XLSX.utils.sheet_to_row_object_array(
			workbook.Sheets[firstSheet]
		)
		const secondSheetRows = XLSX.utils.sheet_to_row_object_array(
			workbook.Sheets[secondSheet]
		)

		setXlsxRows(excelRows)
		setChoiceListSheetRows(secondSheetRows)
		const checkObj = excelRows.filter(
			({ name }) => name && name.substring(0, 6) === 'check_'
		)[0]

		if (!checkObj)
			return setXlsxError(
				'This file is not following the right format, please double check it and reupload'
			)

		checkData({
			variables: { name: checkObj.name },
		})
	}

	const handleUploadCheck = () => {
		setOpenDialog(false)
		const {
			singleUpload: { filename, path },
		} = data
		setUploadVariables({
			json: JSON.stringify(xlsxRows),
			choiceListJson: JSON.stringify(choiceListSheetRows),
			file: filename,
			path,
		})
	}
	const processUploadedFile = (files) => {
		if (typeof FileReader !== 'undefined') {
			const reader = new FileReader()
			if (reader.readAsBinaryString) {
				reader.onload = (e) => {
					setResult(reader.result)
				}
				reader.readAsBinaryString(files[0])
			}
		}
	}

	const handleConfirmation = () => {
		return createUpload({
			variables: {
				input: uploadVariables,
			},
		})
	}

	const handleDialogRejection = () => {
		window.location.reload()
	}

	const handleDialogClose = () => setOpenDialog(false)

	useEffect(() => {
		if (data.singleUpload) {
			handleUploadCheck()
		}
	}, [data])

	useEffect(() => {
		if (uploadVariables) {
			handleConfirmation()
			refetch()
		}
	}, [uploadVariables])

	useEffect(() => {
		if (check) {
			setOpenDialog(true)
		}
	}, [check])

	useEffect(() => {
		if (result) {
			processExcel(result, data)
			setResult(null)
		}
	}, [result])
	if (!currentUser) return <Navigate to="/" />
	if (upload) {
		return <Navigate to={`/check/${upload.checkName}`} />
	}

	return (
		<>
			<Header />
			{loading || checkLoading || uploadLoading ? (
				<Progress />
			) : (
				<>
					<h2>{t('file_upload')}</h2>
					<p className="lead">
						{t('upload_file_to')} <b>{t('create_check')}</b>
					</p>
					<div>
						<form
							id="file-upload-form"
							className="uploader"
							encType={'multipart/form-data'}
						>
							<input
								id="file-upload"
								type="file"
								name="fileUpload"
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								onChange={({ target: { files } }) => {
									setXlsxError(null)
									const file = files[Object.keys(files)[0]]
									if (
										file.type !==
										'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
									) {
										return setXlsxError('Please upload .xlsx format')
									} else if (file) {
										setUploadedFile({ singleFile: file, files })
										return processUploadedFile(files)
									}
								}}
							/>
							<label htmlFor="file-upload" id="file-drag">
								<img id="file-image" src="#" alt="Preview" className="hidden" />
								<div id="start">
									<i className="fa fa-download" aria-hidden="true"></i>
									<div>
										{uploadedFile.singleFile
											? uploadedFile.singleFile.name
											: t('select_xlsx_file_and_drag')}
									</div>
									<div id="notxlsx">{xlsxError}</div>
									<span id="file-upload-btn" className="btn btn-primary">
										{t('select_file')}
									</span>
								</div>
								<div id="response">
									{uploadError && !xlsxError && (
										<>
											<div id="messages">{uploadError?.message}</div>
											{uploadError.graphQLErrors && (
												<div id="messages">
													<p>
														{
															uploadError?.graphQLErrors[0]?.message?.exception
																?.errors[0]?.message
														}
													</p>
													<p>
														please double check:{' '}
														{
															uploadError?.graphQLErrors[0]?.message
															//?.exception?.errors[0]?.value
														}
													</p>
												</div>
											)}
										</>
									)}
								</div>
							</label>
						</form>
						{uploadedFile.singleFile && !uploadError && !xlsxError && (
							<StyledButton
								variant="contained"
								color="secondary"
								onClick={() => {
									singleUpload({
										variables: { file: uploadedFile.singleFile },
									})
								}}
							>
								UPLOAD
							</StyledButton>
						)}

						<Dialog
							open={openDialog}
							onClose={handleDialogClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">{'Confirm!'}</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									There is already a check has been uploaded with this name, are
									you sure you want to overwrite it ?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										singleUpload({
											variables: { file: uploadedFile.singleFile },
										})
									}}
									color="secondary"
									autoFocus
								>
									Yes
								</Button>
								<Button onClick={handleDialogRejection} color="primary">
									No
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				</>
			)}
		</>
	)
}

export default Upload
