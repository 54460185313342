import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import msfLogo from '../../../images/msflogo-trans-background.png'
import './index.css'
const Footer = () => {
	const { i18n } = useTranslation()

	const [language, setLanguage] = useState()
	const changeLanguage = (lng) => {
		setLanguage(lng)
		i18n.changeLanguage(lng)
	}
	const getLanguage = () => i18n.language || window.localStorage.i18nextLng

	useEffect(() => {
		setLanguage(getLanguage())
	}, [])

	return (
		<footer className="footer">
			<div style={{ paddingLeft: '20px' }}>
				<a href="/#" className="footerLink">
					<img src={msfLogo} alt="msfLogo" className="logo" />
					<p>WeCheck</p>
				</a>
			</div>

			<div style={{ paddingRight: '20px' }}>
				<select
					onChange={({ target: { value } }) => changeLanguage(value)}
					value={language}
				>
					<option value="en">English</option>
					<option value="fr">Français</option>
					<option value="es">Espagnol</option>
				</select>
			</div>
		</footer>
	)
}

export default Footer
