/**
 * Admin Control Component
 *
 * This component is designed for super admins to search for users and view their details.
 * It allows super admins to search for a user by email and view relevant information such as
 * the user's first login, last login, project code, and associated departments.
 * It also provides guidelines for super admins to handle various user scenarios.
 *
 * @returns {JSX} A JSX component that enables super admins to search for and view user details.
 */
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import Moment from 'react-moment'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../CurrentUserContext'
import {
	TextField,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Paper,
} from '@mui/material'
import { validateEmail } from '../../functions'
import Progress from '../../UI/Progress'
import Header from '../../UI/Header'
import {
	StyledButton,
	StyledContainer,
	StyledTable,
	StyledTableContainer,
	StyledTableCell,
} from './useStyles'
import './styles.css'

const USER = loader('./graphql/userByEmail.graphql')

const AdminControl = () => {
	const [userEmail, setUserEmail] = useState('')
	const currentUser = useCurrentUser()
	const isSuperAdmin =
		currentUser &&
		currentUser.groups.find(({ group }) => group === 'superAdmin')
	const [
		fetchUserByMail,
		{ data: { userByEmail = null } = {}, loading, error },
	] = useLazyQuery(USER)

	const hanldeFetchUser = () => {
		return fetchUserByMail({
			variables: {
				email: userEmail.trim().toLowerCase(),
			},
		})
	}

	if (!currentUser) return <Navigate to="/" />
	if (currentUser && !isSuperAdmin) return <Navigate to="/" />

	return (
		<>
			<Header />
			<div className="form-container">
				<TextField
					id="outlined-basic"
					label="User Email"
					variant="outlined"
					required
					name="User Email"
					color="secondary"
					multiline
					onChange={({ target: { value } }) => setUserEmail(value)}
				/>
				<StyledButton
					type="submit"
					variant="contained"
					color="secondary"
					disabled={!validateEmail(userEmail)}
					onClick={hanldeFetchUser}
				>
					Search
				</StyledButton>
			</div>
			<StyledTableContainer
				component={Paper} //className={root}
			>
				<StyledTable //className={table}
					stickyHeader
					aria-label="sticky table"
				>
					<TableHead>
						<TableRow>
							<StyledTableCell>Email</StyledTableCell>
							<StyledTableCell>First login</StyledTableCell>
							<StyledTableCell>Last login</StyledTableCell>
							<StyledTableCell>Project code</StyledTableCell>
							<StyledTableCell>Departments</StyledTableCell>
						</TableRow>
					</TableHead>
					{userByEmail && (
						<TableBody>
							<TableRow>
								<TableCell>{userByEmail.email}</TableCell>
								<TableCell>
									<Moment format="DD-MM-YYYY">
										{new Date(Number(userByEmail.createdAt))}
									</Moment>
								</TableCell>
								<TableCell>
									<Moment format="DD-MM-YYYY">
										{new Date(Number(userByEmail.updatedAt))}
									</Moment>
								</TableCell>
								<TableCell>{userByEmail?.project?.code}</TableCell>
								<TableCell>
									{userByEmail.departments.length
										? userByEmail.departments.map(({ name }) => name).join(',')
										: ''}
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</StyledTable>
			</StyledTableContainer>
			{loading && <Progress />}
			{error && (
				<div>
					<span>{error.graphQLErrors[0].message}</span>
				</div>
			)}
			<StyledContainer maxWidth="md">
				<h3>Guide lines:</h3>
				<p>- The first login is the first time user tried to log in.</p>
				<p>- The last login is the last time user tried to log in.</p>
				<p>
					- If the user is sending a request that he/she is not able to log in
					and can not provide a screenshot we should check the last login to
					make sure the user is not confused with another mailbox.
				</p>
				<p>
					- If the last login date does not match the date the user provided, we
					should ask the user to make sure he/she is using the right mailbox.
				</p>
				<p>
					- If the user is a field user and the project code is empty, it means
					there is a problem in the user configuration and you should contact
					Sysapp/Dr Watson to add the project to the user.
				</p>
				<p>
					- If the project code is the same as the coordo project, the user is a
					coordo.
				</p>
				<p>
					- If the department is empty, it means the department is not added
					correctly so please contact Sysapp/Dr Watson.
				</p>
				<p>
					- If the department and project code is available and the user still
					can not log in, it means it's an internal problem, we should ask the
					user to contact the project ICT officer or try another browser to make
					sure the problem is not from WeCheck.
				</p>
				<p>
					- If you entered an email address, and you are sure of the email you
					entered, you still get no results or error: (email is not found),
					which means the user did not try to log in at all, please get back to
					the user first.
				</p>
				<p>- HQ users should have no project code.</p>
			</StyledContainer>
		</>
	)
}

export default AdminControl
