import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { loader } from 'graphql.macro'

import Progress from '../Progress'
import ProjectsList from '../ProjectsList'

const CELL = loader('./graphql/cell.graphql')
const MISSION = loader('./graphql/mission.graphql')

const AssignProjectsToBatches = ({ code, filterBy }) => {
	const [cellData, { data: { cell = null } = {}, loading }] = useLazyQuery(CELL)

	const [
		missionData,
		{ data: { mission = null } = {}, loading: missionLoading },
	] = useLazyQuery(MISSION)

	useEffect(() => {
		if (filterBy === 'cell') {
			//Fetch cell data
			cellData({
				variables: {
					code,
				},
			})
		} else if (filterBy === 'mission') {
			//Fetch mission data
			missionData({
				variables: {
					code,
				},
			})
		}
	}, [filterBy, code])

	return (
		<>
			{loading || missionLoading ? (
				<Progress />
			) : (mission && mission.projects) || (cell && cell.projects) ? (
				<ProjectsList
					projects={filterBy === 'cell' ? cell.projects : mission.projects}
				/>
			) : null}
		</>
	)
}

export default AssignProjectsToBatches
