/**
 * FilterByCellOrMission Component
 *
 * The `FilterByCellOrMission` component is a React component responsible for filtering data based on cell or mission,
 * and it is used in various parts of the application.
 *
 * @param {boolean} checkTable - A flag to indicate whether to display the filter by status and date for the table.
 * @param {boolean} hQHomePage - A flag to indicate whether the component is used on the HQ home page.
 * @param {string} view - The view to display.
 * @param {boolean} outOfOffice - A flag to indicate if the component is used for out-of-office management.
 *
 * @return {JSX.Element} A component that allows users to filter data by cell or mission and other criteria.
 */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import { useCurrentUser } from '../../CurrentUserContext'
import OooTable from '../OooTable'
import Progress from '../Progress'
import BreakDownBox from '../BreakDownBox'
import FilterSelect from '../FilterSelect'
import DateFilter from '../DateFilter'
import { sortAlphabetically } from '../../functions'
import './styles.css'

const ALLDEPARTMENTS = loader('./graphql/allDepartments.graphql')
const CELLS = loader('./graphql/cells.graphql')
const MISSIONS = loader('./graphql/missions.graphql')

const FilterByCellOrMission = ({
	checkTable,
	hQHomePage,
	view,
	outOfOffice,
}) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()

	const [selectedStatus, setSelectedStatus] = useState('all')
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')
	const [filterBy, setFilterBy] = useState(() => {
		return window.localStorage.getItem('filterBy') || 'mission'
	})
	const [selectedDepartmentId, setSelectedDepartmentId] = useState(() => {
		return window.localStorage.getItem('selectedDepartmentId')
	})
	const [selectedCode, setSelectedCode] = useState(() => {
		return window.localStorage.getItem('selectedCode') || null
	})
	const [selectedProjectCode, setSelectedProjectCode] = useState(() => {
		return window.localStorage.getItem('selectedProjectCode') || null
	})
	const [selectedYear, setSelectedYear] = useState(
		() =>
			Number(window.localStorage.getItem('selectedHomeYear')) ||
			new Date().getFullYear()
	)

	const {
		data: { allDepartments = null } = {},
		loading: allDepartmentsLoading,
	} = useQuery(ALLDEPARTMENTS)
	const { data: { cells = null } = {}, cellsLoading } = useQuery(CELLS)
	const { data: { missions = null } = {}, missionsLoading } = useQuery(MISSIONS)

	return (
		<>
			{allDepartmentsLoading || cellsLoading || missionsLoading ? (
				<Progress />
			) : (
				<>
					{cells && missions && (
						<>
							{/* Render filter options */}
							<div className="formContainer">
								{/* Filter by Cell or Mission */}
								<FilterSelect
									label={t('filter_by')}
									value={filterBy}
									options={['cell', 'mission'].map((el) => ({
										label: t(el),
										value: el,
									}))}
									onChange={({ target: { value } }) => {
										setFilterBy(value)
										window.localStorage.setItem('filterBy', value)
										setSelectedCode(null)
										window.localStorage.removeItem('selectedCode')
									}}
								/>
								{/* Filter by Cell or Mission Code */}
								<FilterSelect
									menuItemValue="code"
									label={filterBy === 'mission' ? t('mission') : t('cell')}
									value={selectedCode || ''}
									options={
										filterBy === 'cell'
											? [
													{ label: t('all_cells'), code: 'all cells' },
													...sortAlphabetically(cells),
											  ]
											: [
													{ label: t('all_missions'), code: 'all missions' },
													...sortAlphabetically(missions),
											  ]
									}
									onChange={({ target: { value } }) => {
										setSelectedCode(value)
										window.localStorage.setItem('selectedCode', value)
										setSelectedProjectCode('all projects')
										window.localStorage.setItem(
											'selectedProjectCode',
											'all projects'
										)
										if (selectedDepartmentId === null) {
											setSelectedDepartmentId('all departments')
											window.localStorage.setItem(
												'selectedDepartmentId',
												'all departments'
											)
										}
									}}
								/>
								{/* Filter by Project Code */}
								<FilterSelect
									menuItemValue="code"
									label={t('project')}
									value={selectedProjectCode || ''}
									options={
										selectedCode &&
										selectedCode !== 'all cells' &&
										filterBy === 'cell'
											? [
													{ label: t('all_projects'), code: 'all projects' },
													...sortAlphabetically(
														cells.find(({ code }) => code === selectedCode)
															?.projects
													),
											  ]
											: selectedCode &&
											  filterBy === 'mission' &&
											  selectedCode !== 'all missions'
											? [
													{ label: t('all_projects'), code: 'all projects' },
													...sortAlphabetically(
														missions.find(({ code }) => code === selectedCode)
															?.projects
													),
											  ]
											: []
									}
									onChange={({ target: { value } }) => {
										setSelectedProjectCode(value)
										window.localStorage.setItem('selectedProjectCode', value)
									}}
								/>
								{/* Filter by Department */}
								<FilterSelect
									menuItemValue="id"
									label={t('department')}
									value={
										selectedDepartmentId ||
										currentUser.departments[0].id ||
										'all departments'
									}
									options={[
										{ name: t('all_departments'), id: 'all departments' },
										...allDepartments,
									].map(({ id, name }) => ({ label: name, id }))}
									onChange={({ target: { value } }) => {
										setSelectedDepartmentId(value)
										window.localStorage.setItem('selectedDepartmentId', value)
									}}
								/>
							</div>

							{
								//Display additional filters based on the view type
								//show the filter by status and date when the view is 'view' or 'checkTable' === true
								checkTable ? (
									<div>
										<FilterSelect
											label={t('status')}
											value={selectedStatus}
											options={[
												{ label: t('all'), value: 'all' },
												{ label: t('received'), value: 'received' },
												{ label: t('sent'), value: 'sent' },
												{ label: t('overdue'), value: 'overdue' },
											]}
											onChange={({ target: { value } }) => {
												setSelectedStatus(value)
											}}
										/>
										<DateFilter
											label={t('from')}
											value={fromDate}
											onChange={({ target: { value } }) =>
												setFromDate(new Date(value).setHours(0, 0, 0, 0))
											}
										/>
										<DateFilter
											label={t('to')}
											value={toDate}
											onChange={({ target: { value } }) =>
												setToDate(new Date(value).setHours(0, 0, 0, 0))
											}
										/>
									</div>
								) : (
									<>
										{/* Year Filter */}
										{!outOfOffice && (
											<FilterSelect
												label={t('year')}
												value={selectedYear}
												options={[
													2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
													2030,
												].map((element, i) => ({
													label: element,
													value: element,
												}))}
												onChange={({ target: { value } }) => {
													window.localStorage.setItem('selectedHomeYear', value)
													setSelectedYear(value)
												}}
											/>
										)}
									</>
								)
							}
							{/* Conditional rendering of OooTable component for out-of-office management */}
							{outOfOffice &&
								selectedDepartmentId &&
								selectedProjectCode &&
								selectedDepartmentId !== 'all' &&
								selectedProjectCode !== 'all' && (
									<OooTable
										showEmail
										departmentId={selectedDepartmentId}
										projectCode={selectedProjectCode}
									/>
								)}
							{/* Render BreakDownBox component based on different views */}
							{checkTable && (
								<BreakDownBox
									checkTable={checkTable}
									filterBy={filterBy}
									selectedCode={selectedCode}
									projectCode={selectedProjectCode}
									departmentId={selectedDepartmentId}
									selectedStatus={selectedStatus}
									fromDate={fromDate}
									toDate={toDate}
									year={selectedYear}
								/>
							)}
							{hQHomePage && (
								<BreakDownBox
									filterBy={filterBy}
									selectedCode={selectedCode}
									projectCode={selectedProjectCode}
									departmentId={selectedDepartmentId}
									selectedStatus={selectedStatus}
									fromDate={fromDate}
									toDate={toDate}
									view={view}
									year={selectedYear}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	)
}

export default FilterByCellOrMission
