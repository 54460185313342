/** @type {*
 * This function is responsible for filtering the array
 * of kobo deployed checks based on the status and to, from dates} */
const filterKoboDeploymentsByDateAndStatus = ({
	koboDeploymentArray,
	//Passing default values in the case of receiving null or undefined to one of these values
	selectedStatus = 'all',
	fromDate = '',
	toDate = '',
}) => {
	return koboDeploymentArray
		.filter(({ status }) =>
			selectedStatus === 'all' ? status : status === selectedStatus
		)
		.filter(({ createdAt }) =>
			toDate
				? new Date(Number(createdAt)).setHours(0, 0, 0, 0) <= toDate
				: createdAt
		)
		.filter(({ createdAt }) =>
			fromDate
				? new Date(Number(createdAt)).setHours(0, 0, 0, 0) >= fromDate
				: createdAt
		)
}

export default filterKoboDeploymentsByDateAndStatus
